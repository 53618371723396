/**
 * This function implements a small subset of the unmaintained `normalizr` library.
 * It was extracted from the library to avoid old dependencies and simplify TypeScript
 * types for the data.
 */

type RequiredObjectSchema = {
  id: number;
};

export type NormalizedEntities<T extends RequiredObjectSchema> = { [key: number | string]: T };
export type NormalizedResultArray<T extends RequiredObjectSchema> = Array<T['id']>;

export type NormalizedResult<T extends RequiredObjectSchema> = {
  entities: NormalizedEntities<T>;
  result: NormalizedResultArray<T>;
};

export type NormalizedInput<T extends RequiredObjectSchema> = Array<T>;

export function normalize<T extends RequiredObjectSchema>(
  objects: NormalizedInput<T>
): NormalizedResult<T> {
  return {
    entities: objects.reduce((acc, object) => {
      acc[object.id] = object;
      return acc;
    }, {} as { [key: number]: T }),
    result: objects.map((object) => object.id),
  };
}
