import { NormalizedResult } from '../../store/Options/normalize';
import { OptionsState } from '../../store/Options/reducers';

/**
 * Options containing this text in their title will be hidden
 */
const keyword = 'discontinued';

interface TitledOption {
  id: number;
  title: {
    rendered: string;
  };
}

/**
 * We only hide the options from the `result` array; by accessing the
 * entity in `entities` directly with the ID, it can be accessed.
 *
 * I'm not sure if this adds any value, but it's good enough for the
 * intended purpose.
 *
 * @param options
 *
 * @returns The same options structure as passed into the function,
 *          but with the `result` array filtered to not contain
 *          any options with titles that contain the text
 *          `discontinued`.
 */
function hideDiscontinuedOptionOfSingleType<T extends TitledOption>(
  options?: null | NormalizedResult<T>
): null | NormalizedResult<T> {
  if (!options) {
    return null;
  }
  return {
    entities: options.entities,
    result: options.result.filter((id) => !options.entities[id].title?.rendered?.includes(keyword)),
  };
}

export function hideDiscontinuedOptions(options?: null | OptionsState): null | OptionsState {
  if (!options) {
    return null;
  }
  return {
    ...options,

    drivetrains: options.drivetrains
      ? hideDiscontinuedOptionOfSingleType(options.drivetrains)
      : undefined,

    tops: options.tops ? hideDiscontinuedOptionOfSingleType(options.tops) : undefined,
    seats: options.seats ? hideDiscontinuedOptionOfSingleType(options.seats) : undefined,
    colorareas: options.colorareas
      ? hideDiscontinuedOptionOfSingleType(options.colorareas)
      : undefined,
    equipments: options.equipments
      ? hideDiscontinuedOptionOfSingleType(options.equipments)
      : undefined,
    boats: options.boats ? hideDiscontinuedOptionOfSingleType(options.boats) : undefined,
    addons: options.addons ? hideDiscontinuedOptionOfSingleType(options.addons) : undefined,
  } as OptionsState;
}
