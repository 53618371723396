import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import * as validator from '../../helpers/validators.js'
import Input from '../../components/Input/Input'
import { Button, Intent } from '@blueprintjs/core'

class LoginForm extends Component {
  render () {
    const { handleSubmit } = this.props
    return (
      <form onSubmit={handleSubmit}>
        <Field name='email' component={Input} type='email' placeholder='Email' label='Email' validate={[ validator.required, validator.email ]} />
        <Field name='password' component={Input} type='password' placeholder='Password' label='Password' validate={[ validator.required ]} />
        <Button className='bp3-large' icon='user' type='submit' intent={Intent.PRIMARY}>Sign in</Button>
      </form>
    )
  }
}

// Decorate the form component
LoginForm = reduxForm({
  form: 'login' // a unique name for this form
})(LoginForm)

export default LoginForm
