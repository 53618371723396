import Raven from 'raven-js'
import { createAction } from 'redux-actions'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { setLoading } from '../Common/actions'

export const setUser = createAction('SET_USER')

export const startListeningToAuth = () => (dispatch, getState) => {
  const user = getState().user
  if (user && user.user) {
    return null
  }
  dispatch(setLoading(true))

  firebase.auth().onAuthStateChanged((response) => {
    dispatch(setLoading(false))
    if (response) {
      // User is signed in.
      dispatch(setUser(response))
      Raven.setUserContext({
        email: response.email,
        id: response.uid,
      })
    } else {
      dispatch(setUser(false))
    }
  })
}

export const signup = ({ email, password, displayName, role = 'Agent' }) => (
  dispatch,
  getState
) => {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((user) => {
      const { uid } = user
      user.updateProfile({
        displayName: displayName,
      })
      let updates = {
        [`/users/${user.uid}`]: { uid, displayName, role },
      }
      firebase.database().ref().update(updates)
    })
    .catch((error) => {
      // Handle Errors here.
      return error
    })
}

export const signInWidthGoogle = () => (dispatch, getState) => {
  // Using a popup.
  var provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('profile')
  provider.addScope('email')
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function (result) {
      // This gives you a Google Access Token.
      // var token = result.credential.accessToken
      // The signed-in user info.
      var user = result.user

      const { uid, displayName } = user
      const role = 'Agent'
      let updates = {
        [`/users/${user.uid}`]: { uid, displayName, role },
      }
      firebase.database().ref().update(updates)
    })
}
