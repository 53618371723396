import { AnimatePresence, motion } from 'framer-motion'
import { useState } from 'react'
import styled from 'styled-components'
import { CloseIcon } from '../../components/Icons'
import Summary from '../../components/Summary'
import { BasketIcon } from './BasketIcon'

const Overlay = styled(motion.div)`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  /* backdrop-filter: blur(3px); */
`

const Cart = (props) => {
  const [showCart, setShowCart] = useState(false)
  const { build, estimatedDeliveryTime, match } = props

  return (
    <>
      {!showCart && (
        <a
          onClick={() => {
            setShowCart(true)
          }}
          alt="Cart summary"
          className="dib"
          style={{ width: '20px', height: '20px', margin: '0 auto' }}
        >
          <BasketIcon />
        </a>
      )}
      <AnimatePresence>
        {showCart && (
          <Overlay
            className="z-max"
            onClick={() => {
              setShowCart(false)
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeInOut', duration: 0.15 }}
          ></Overlay>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {showCart && (
          <motion.section
            className="absolute top-0 right-0 z-max shadow-4 w-100  mt0  "
            style={{ maxWidth: '600px', width: '100%', height: '100vh' }}
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ ease: 'easeOut', duration: 0.15, delay: 0.05 }}
          >
            <div className="ph3 ph4-ns pv4 bg-white overflow-scroll pa1 h-100">
              <div
                className="top-0 right-0 absolute w2 h2 mt3 mr3"
                style={{ width: '24px', height: '24px' }}
                onClick={() => {
                  setShowCart(false)
                }}
              >
                <CloseIcon />
              </div>
              <Summary
                match={match}
                build={build}
                estimatedDeliveryTime={estimatedDeliveryTime}
                handleNextClick={() => {
                  setShowCart(false)
                }}
              />
            </div>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}

export default Cart
