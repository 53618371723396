import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { connect, useSelector } from 'react-redux'

import Currency from '../../components/Currency/Currency'
import DefinitionListRow from '../../components/DefinitionListRow/DefinitionListRow'
import DefinitionListTitle from '../../components/DefinitionListTitle/DefinitionListTitle'

import { getCommonOptions } from '../../store/Common/actions'

import { lightenDarkenColor } from '../../helpers/colorTransforms'
import { getCurrency } from '../../helpers/getCurrency'
import { getOptions } from '../../store/Options/selectors'
import { RightArrow } from '../Icons/RightArrow'

import reserveCheckoutThumb from './reserve-checkout-thumb.jpg'
import requestCheckoutThumb from './request-checkout-thumb.jpg'
import checkMark from '../Summary/checkMark.svg'

const Cart = (props) => {
  const [payDeposit, setPayDeposit] = useState(true)

  const options = useSelector(getOptions)

  useEffect(() => {
    props.getCommonOptions()
    // eslint-disable-next-line
  }, [props.commonOptions, props.getCommonOptions])

  const { build, handleNextClick } = props

  const boat = options.boats ? options.boats.entities[options.boats.result[0]] : false
  const drivetrain =
    build && options.drivetrains ? options.drivetrains.entities[build.drivetrain] : false
  const seat = build && options.seats ? options.seats.entities[build.seat] : false
  const top = build && options.tops ? options.tops.entities[build.top] : false
  const equipmentLineItems = build ? build.equipmentLineItems : false
  const addonLineItems = build ? build.addonLineItems : false
  let colors = false

  // Loop through colors set in the build
  if (build && options && build.colors && options.colorareas) {
    colors = options.colorareas.result.map((colorarea) => {
      const colorArea = options.colorareas.entities[colorarea]
      const colorList = colorArea.acf.colors
      const colorItem = colorList.filter((item) => {
        return item.color.term_id === parseInt(build.colors[colorarea], 10)
      })[0]
      if (colorItem) {
        // TODO: Find out why is color empty sometimes
        return { colorArea, colorItem }
      }
      return false
    })
    colors = colors.filter((item) => !!item)
  }

  const buildCurrency = getCurrency(build?.currency)?.label

  let totalCost = 0
  if (drivetrain && top && seat) {
    totalCost +=
      Number(drivetrain.prices?.[buildCurrency]) +
      Number(top.prices?.[buildCurrency]) +
      Number(seat.prices?.[buildCurrency])
  }

  const showPrice = true

  return (
    <>
      {boat && drivetrain && (
        <dl className="mt0">
          <div className="flex items-center justify-between pt3 pb3 bb b--black-05">
            <div className="tc w-100">
              <dt className="geo-semibold p-0 black mb2">{boat.title.rendered}</dt>
              <dt className="p-2">with {drivetrain.acf.display_name}</dt>
              <dt className="geo-regular f6 f5-ns black-70 mt2 dib tc w-100 mt3">
                <div className="geo-regular ph3 pv2 new-grey dib w-100">
                  {drivetrain.acf.meta[0].title}:{' '}
                  <span className="black geo-semibold">{drivetrain.acf.meta[0].value}</span>
                </div>
              </dt>
            </div>
            {/* <dd className="b tr">
              {showPrice && (
                <Currency className="geo-semibold tr f5 black-90">{drivetrain.prices[buildCurrency]}</Currency>
              )}
            </dd> */}
          </div>
        </dl>
      )}
      {seat && (
        <DefinitionListRow
          label={`Seats: ${seat.acf.display_name}`}
          currency
          values={seat.prices}
          showPrice={showPrice}
        />
      )}
      {top && (
        <DefinitionListRow
          label={`${top.acf.display_name}`}
          currency
          values={top.prices}
          showPrice={showPrice}
        />
      )}
      <DefinitionListTitle title="Color Selection" />
      {colors &&
        colors.map((color) => {
          const colorPrice = color.colorItem?.prices[buildCurrency]
          totalCost += Number(colorPrice)
          return (
            <DefinitionListRow
              key={color.colorArea.id}
              color={color.colorItem.color.slug}
              label={`${color.colorArea.acf.display_name} - ${color.colorItem.color.name}`}
              currency
              values={color.colorItem?.prices}
              showPrice={showPrice}
            />
          )
        })}
      {options && options.equipments && equipmentLineItems && (
        <DefinitionListTitle title="Equipments" />
      )}
      {options &&
        options.equipments &&
        equipmentLineItems &&
        equipmentLineItems.map((item) => {
          const equipmentItem = options.equipments.entities[item.equipment]

          if (!equipmentItem) {
            return null
          }
          totalCost += Number(equipmentItem.prices?.[buildCurrency])
          return (
            <DefinitionListRow
              key={item.equipment}
              label={equipmentItem.acf.display_name}
              currency
              values={equipmentItem.prices}
              showPrice={showPrice}
            />
          )
        })}
      {options && options.addons && addonLineItems && <DefinitionListTitle title="Addons" />}
      {options &&
        options.addons &&
        addonLineItems &&
        addonLineItems.map((item) => {
          const addonItem = options.addons.entities[item.addon]

          if (!addonItem) {
            return null
          }
          totalCost += Number(addonItem.prices?.[buildCurrency])
          return (
            <DefinitionListRow
              key={item.addon}
              label={addonItem.acf.display_name}
              currency
              values={addonItem.prices}
              showPrice={showPrice}
            />
          )
        })}

      {showPrice && (
        <div className="flex justify-between">
          <p className="f5 geo-regular mb2">Total Cost</p>
          <Currency
            className="geo-semibold tr p-0 black bb border-green"
            prices={{ [buildCurrency]: totalCost }}
            showVat={true}
          />
        </div>
      )}
      {!showPrice && (
        <p className="roboto-regular mt3 tc o-40 lh-copy">
          The Semi-Pro models are project boats where each build is priced uniquely.
          <br />
          Please complete your specification and submit it.
          <br />
          Goldfish sales department will return to you asap with a complete quote for your build.
        </p>
      )}

      {/* <div role="radiogroup" aria-labelledby="deposit" className="mt4">
        <RadioButton selected={payDeposit} handleClick={() => setPayDeposit(true)} tabindex="0">
          <h4 className="mt0 geo-semibold p-3 mb1">Reserve Build Slot</h4>
          <Tag hex="#fff">{props.buildSlotLabel && props.buildSlotLabel}</Tag>
          <p className="geo-regular p-3 mt0">{props.buildSlotText && props.buildSlotText}</p>
        </RadioButton>
        <RadioButton selected={!payDeposit} handleClick={() => setPayDeposit(false)} tabindex="0">
          <h4 className="mt0 geo-semibold p-3 mb0">Request Information</h4>
          <p className="mt1 geo-regular p-3 mt0">
            {props.requestInformationText && props.requestInformationText}
          </p>
        </RadioButton>
      </div> */}
      <OrderTypeChoiceContainer>
        <OrderTypeChoice selected={payDeposit} onClick={() => setPayDeposit(true)}>
          <ImageWrapper>
            <ImageBackground imgUrl={reserveCheckoutThumb}>
              {payDeposit ? <Icon imgUrl={checkMark} alt="Checkmark" /> : null}
            </ImageBackground>
          </ImageWrapper>
          <ChoiceDescription>
            <h4 className="mt0 geo-semibold p-3+ mb1">Reserve Build Slot</h4>
            <p className="geo-regular p-3 mt0">{props.buildSlotText && props.buildSlotText}</p>
          </ChoiceDescription>
        </OrderTypeChoice>
        <ChoiceOr>
          <p>or</p>
        </ChoiceOr>
        <OrderTypeChoice selected={!payDeposit} onClick={() => setPayDeposit(false)}>
          <ImageWrapper>
            <ImageBackground imgUrl={requestCheckoutThumb}>
              {!payDeposit ? <Icon imgUrl={checkMark} alt="Checkmark" /> : null}
            </ImageBackground>
          </ImageWrapper>
          <ChoiceDescription>
            <h4 className="mt0 geo-semibold p-3+ mb0">Request Information</h4>
            <p className="mt1 geo-regular p-3 mt0">
              {props.requestInformationText && props.requestInformationText}
            </p>
          </ChoiceDescription>
        </OrderTypeChoice>
      </OrderTypeChoiceContainer>

      <div className="center mt4 w-100">
        <NextLink
          onClick={() => {
            handleNextClick()
          }}
          to={
            payDeposit
              ? `${props.match.url}/quote/pay-deposit`
              : `${props.match.url}/quote/request-build-time`
          }
          className="p-1 w-100 pv2 tc geo-semibold dib link ttu"
        >
          Next
          <RightArrow className="ml1" width={14} title="" />
        </NextLink>
      </div>

      <div className="bg-light-gray pa3 mt3">
        <p className="f5 geo-semibold">Lead Time</p>
        <p className="p-3 geo-regular">
          Estimated time of completion depends on model of choice, configuration options and
          delivery location. Expect 100 days for Tender, Sport and Bullet-series.
        </p>
      </div>
    </>
  )
}

const mapStateToProps = (state, props) => ({
  buildSlotText: state.common.options && state.common.options.reserve_build_slot_text,
  buildSlotLabel: state.common.options && state.common.options.reserve_build_slot_label,
  requestInformationText: state.common.options && state.common.options.request_information_text,
})

export default connect(mapStateToProps, {
  getCommonOptions,
})(Cart)

const NextLink = styled(Link)`
  background: #fb5515;
  color: white;
  text-decoration: none;
  :hover {
    color: white;
    text-decoration: none;
    background-color: ${(props) => lightenDarkenColor('#fb5515', 20)};
  }
`

const OrderTypeChoiceContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-top: 40px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const OrderTypeChoice = styled.div`
  background: ${(props) => (props.selected ? '#fb5515' : '#F0F4F4')};
  color: ${(props) => (props.selected ? '#ffffff' : '#000000')};
  display: flex;
  flex-direction: column;
  cursor: pointer;
  flex: 1;
  position: relative;
  box-sizing: border-box;
  &:hover {
    transition ease-out 0.3s;
    background: ${(props) => !props.selected && '#e8efee'};

  }
  &:hover > div > div {
    transition ease-out 0.3s;
    transform: ${(props) => !props.selected && 'scale(1.1)'};
  }
  @media screen and (max-width: 768px) {
    flex-direction: row;
  }
`

const ChoiceDescription = styled.div`
  padding: 20px;
  margin: 0 auto;
  flex: 1;
`

const ChoiceOr = styled.div`
  align-self: center;
  font-size: 12px;
`

const ImageWrapper = styled.div`
  overflow: hidden;
  @media screen and (max-width: 768px) {
    width: 50%;
    min-height: 240px;
  }
  @media screen and (min-width: 769px) {
    height: 160px;
    justify-content: center;
    align-items: center;
  }
`

export const ImageBackground = styled.div`
  position: relative;
  background-repeat: no-repeat;
  background: url(${(props) => props.imgUrl});
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
`

export const Icon = styled.div`
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: url(${(props) => props.imgUrl});
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;

  @media screen and (max-width: 768px) {
    right: auto;
    bottom: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`
