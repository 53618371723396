import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const Swatch = styled.div`
  width: 100px;
  height: 100px;
  background-color: ${(props) => props.hex};
  background-image: url(${(props) => props.image});
  background-size: cover;
`

const LargeSwatch = styled.div`
  background-color: ${(props) => props.hex};
  background-image: url(${(props) => props.image});
  background-size: cover;
  border: 1px solid #a6dde8;
`

const ColorSwatch = ({ color, small }) => {
  if (color === undefined) {
    return null
  }
  if (color.acf.hex) {
    if (small) {
      return (
        <Swatch
          className={`br-100 dib colortag`}
          hex={`#${color.acf.hex}`}
          image={`${color.acf.image}`}
        />
      )
    }

    return (
      <LargeSwatch
        className={`w1 h1 br-100 absolute top-1 left-1 colortag`}
        hex={`#${color.acf.hex}`}
      />
    )
  }
  return null
}

ColorSwatch.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default ColorSwatch
