import PropTypes from 'prop-types'
import React from 'react'

const InputTextHolder = ({
  autocomplete,
  placeholder,
  classes,
  input,
  label,
  type,
  required,
  inputText,
  meta: { touched, error, warning },
}) => (
  <div className={classes}>
    <label className="bp3-label roboto-regular" htmlFor={input.name}>
      <span className="geo-semibold">{label}</span>
      <input
        {...input}
        className={`bp3-fill bp3-input geo-regular pv3 ${error && touched && 'bp3-intent-danger'}`}
        type={type}
        placeholder={placeholder}
        autoComplete={autocomplete}
        required={required}
        value={inputText}
      />

      {touched && error && <span className="mt1 db red roboto-regular">{error}</span>}
    </label>
  </div>
)

InputTextHolder.propTypes = {
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  classes: PropTypes.string,
  input: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  inputText: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
}

export default InputTextHolder
