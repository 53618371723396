import PropTypes from 'prop-types'
import Currency from '../../components/Currency/Currency'

const DefinitionListRow = ({ label, values, showPrice }) => (
  <dl className="mt0">
    <div className="flex items-center justify-between pt0 pb3 bb b--black-05">
      <dt className="roboto-regular black-70 p-3">{label}</dt>
      {showPrice && (
        <dd className="b tr">
          <Currency prices={values} />
        </dd>
      )}
    </div>
  </dl>
)

DefinitionListRow.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  showPrice: PropTypes.bool,
}

DefinitionListRow.defaultProps = {
  showPrice: true,
}

export default DefinitionListRow
