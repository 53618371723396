interface Props {
  className?: string;
  width?: number;
  title?: string;
}

export const RightArrow = (props: Props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width}
    className={props.className}
    x="0px"
    y="0px"
    viewBox="0 0 20.8 17.4"
    style={
      {
        // This setting is deprecated, see: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/enable-background
        enableBackground: 'new 0 0 20.8 17.4',
      } as any
    }
  >
    {props.title ? <title>{props.title}</title> : null}
    <path
      fill="#fff"
      d="M1.5,7.2h14.2L11,2.6C10.4,2,10.4,1,11,0.4c0.6-0.6,1.5-0.6,2.1,0l7.3,7.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
      c0.1,0.1,0.2,0.3,0.3,0.5c0,0,0,0,0,0c0.1,0.2,0.1,0.3,0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
      c0,0.2,0,0.4-0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0.2-0.2,0.3-0.3,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
      c0,0,0,0,0,0L13.1,17c-0.3,0.3-0.7,0.4-1.1,0.4c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l4.7-4.6H1.5C0.7,10.2,0,9.5,0,8.7
      C0,7.9,0.7,7.2,1.5,7.2z"
    />
  </svg>
);
