import React from 'react'

function Icon() {
  return (
    <svg
      style={{ height: '13px' }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 4 13"
    >
      <path
        fill="#000"
        d="M1.625 9.75a1.625 1.625 0 110 3.25 1.625 1.625 0 010-3.25zm0-4.875a1.625 1.625 0 110 3.25 1.625 1.625 0 010-3.25zm0-4.875a1.625 1.625 0 110 3.25 1.625 1.625 0 010-3.25z"
      ></path>
    </svg>
  )
}

export default Icon
