import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import firebase from 'firebase/app'
import 'firebase/auth'
import { Redirect } from 'react-router-dom'
// Redux
import { signInWidthGoogle } from '../../store/User/actions'
// Components
import Section from '../../components/Section/Section'
import LoginForm from '../../components/LoginForm/LoginForm'

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = { errorCode: null, errorMessage: null }
    this.handleLogin = this.handleLogin.bind(this)
    this.handleGoogleSignup = this.handleGoogleSignup.bind(this)
  }

  static propTypes = {
    user: PropTypes.object,
  }

  componentDidMount() {}

  handleLogin(values) {
    const _this = this
    const { email, password } = values
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code
        var errorMessage = error.message
        _this.setState({ errorCode: errorCode, errorMessage: errorMessage })
      })
  }

  handleGoogleSignup() {
    this.props.signInWidthGoogle()
  }

  render() {
    // Check if the user has been authenticated
    const { user } = this.props
    const isAuthenticated = user && user.user
    const { from } = this.props.location.state || '/'
    const { errorCode, errorMessage } = this.state
    return (
      <Section>
        {isAuthenticated && <Redirect to={from || '/'} />}
        {from && (
          <p>
            You must log in to view the page at
            <code>{from.pathname}</code>
          </p>
        )}
        <h1>Login</h1>
        {errorCode && (
          <div className="bp3-callout bp3-intent-danger">
            <h5>{errorCode}</h5>
            {errorMessage}
          </div>
        )}
        <LoginForm onSubmit={this.handleLogin} />
        <br />
      </Section>
    )
  }
}

// eslint-disable-next-line
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, { signInWidthGoogle })(Login)
