import PropTypes from 'prop-types';
import React from 'react';

const Panel = ({title, children}) => (
  <section className='bp3-card bp3-elevation-1 mv3'>
    <h5>{title}</h5>
    <hr />
    {children}
  </section>
)

Panel.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired
}

export default Panel
