interface Props {
  className?: string;
  width?: number;
  title?: string;
  dataValue?: string;
}

export const BackArrow = (props: Props) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={props.width}
    className={props.className}
    x="0px"
    y="0px"
    viewBox="0 0 20.8 17.4"
    data-value={props.dataValue}
    style={
      {
        // This setting is deprecated, see: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/enable-background
        enableBackground: 'new 0 0 20.8 17.4',
      } as any
    }
  >
    {props.title ? <title>{props.title}</title> : null}
    <path
      fill="#fff"
      d="M15.5,7.2H5.2l4.7-4.6c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L0.4,7.6c0,0,0,0,0,0c0,0,0,0,0,0
  c0,0,0,0,0,0c0,0,0,0,0,0C0.3,7.8,0.2,8,0.1,8.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0C0,8.3,0,8.5,0,8.7c0,0,0,0,0,0c0,0,0,0,0,0
  c0,0,0,0,0,0c0,0,0,0,0,0c0,0.2,0,0.3,0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0.2,0.2,0.3,0.3,0.5c0,0,0,0,0,0c0,0,0,0,0,0
  c0,0,0,0,0,0c0,0,0,0,0,0L7.8,17c0.3,0.3,0.7,0.4,1.1,0.4c0.4,0,0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1l-4.7-4.6h10.4
  c1.3,0,2.3,1,2.3,2.3v3.4c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-3.4C20.8,9.6,18.5,7.2,15.5,7.2z"
    />
  </svg>
);
