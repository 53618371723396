import PropTypes from 'prop-types';
import React from 'react';

const DefinitionListTitle = ({title}) => (
  <p className='mb3 mt4 pb2 ttu p-3 geo-semibold black-80 bb b--black-20'>{title}</p>
)

DefinitionListTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export default DefinitionListTitle
