import React from 'react'

import stuff from './data'

import DrivetrainCard from '../../components/DrivetrainCard/DrivetrainCard'

const { drivetrainCardProps } = stuff

const Elements = () => {
  return (
    <>
      <h1>A page to display components used on the site</h1>
      <h2>Drivetrain Card</h2>
      <DrivetrainCard {...drivetrainCardProps}></DrivetrainCard>
    </>
  )
}

export default Elements
