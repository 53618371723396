import { createActions } from 'redux-actions'
import firebase from 'firebase/app'
import 'firebase/database'

export const { fetchUsersRequest, fetchUsersSuccess, fetchUsersFailure } = createActions(
  'FETCH_USERS_REQUEST',
  'FETCH_USERS_SUCCESS',
  'FETCH_USERS_FAILURE'
)

// Fetch all users from Firebase
const fetchUsers = () => {
  return {
    types: [fetchUsersRequest().type, fetchUsersSuccess().type, fetchUsersFailure().type],
    promise: firebase
      .database()
      .ref('users')
      .once('value'),
  }
}

// Load all users and fetch it from Firebase unless it is cached in Redux store
export const loadAllUsers = () => (dispatch, getState) => {
  return dispatch(fetchUsers())
}
