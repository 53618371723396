import { Field } from 'redux-form'

import * as validator from '../../helpers/validators'
import Panel from '../Panel/Panel'
import Input from '../Input/Input'
import SelectCountry from '../Select/SelectCountry'
import Textarea from '../Textarea/Textarea'
import Select from '../Select/Select'
import countryCodes from '../QuoteForm/countryCodes'
import { config } from '../../config'

const CustomerInformation = ({ handleChange }) => (
  <Panel title="Customer information">
    <fieldset className="ba b--transparent ph0 mh0">
      <legend className="clip">Customer Information</legend>
      <div className="flex flex-wrap">
        <Field
          name="firstName"
          component={Input}
          label="First Name"
          placeholder="Add client's first name"
          type="text"
          validate={[validator.required]}
          autocomplete="first name"
          classes="flex-auto mr4-ns"
        />
        <Field
          name="lastName"
          component={Input}
          label="Last Name"
          placeholder="Add client's last name"
          type="text"
          validate={[validator.required]}
          autocomplete="last name"
          classes="flex-auto"
        />
      </div>
      <div className="flex flex-wrap mt3">
        <div className="flex w-50 ">
          <Field
            name="countryCode"
            component={Input}
            label="Country code"
            placeholder="Country code"
            type="tel"
            validate={[validator.required]}
            autocomplete="tel"
            classes=" mr4-ns w4"
          />
          <Field
            name="phoneNumber"
            component={Input}
            label="Phone Number"
            placeholder="Add client's phone number"
            type="tel"
            validate={[validator.required]}
            autocomplete="tel"
            classes="w-100 mr4-ns"
          />
        </div>
        <Field
          name="email"
          component={Input}
          label="Email"
          placeholder="Add client's email"
          type="email"
          validate={[validator.required, validator.email]}
          autocomplete="email"
          classes="flex-auto"
        />
      </div>

      <div className="flex flex-wrap mt3">
        <Field
          name="address"
          component={Input}
          label="Address"
          placeholder="Add client's address"
          type="adress"
          validate={[validator.required]}
          autocomplete="street address"
          classes="flex-auto"
        />
        <Field
          name="zip"
          component={Input}
          label="Zip code"
          placeholder="Add client's zip code"
          type="adress"
          validate={[validator.required]}
          autocomplete="street address"
          classes="flex-auto ml4"
        />
      </div>
      <div className="flex flex-wrap mt3">
        <Field
          name="city"
          component={Input}
          label="City"
          placeholder="Add client's city"
          type="adress"
          validate={[validator.required]}
          autocomplete="street address"
          classes="flex-auto"
        />
        <Field
          name="country"
          handleChange={handleChange}
          component={SelectCountry}
          label="Country"
          placeholder="Add client's country"
          type="select"
          options={countryCodes}
          classes="flex-auto ml4"
          validate={[validator.required]}
          autocomplete="country"
        />
      </div>
      <div className="flex mt3">
        <Field
          name="distributor"
          component={Input}
          label="Preferred distributor"
          placeholder="Add client's preferred distributor"
          type="text"
          validate={[]}
          autocomplete="preferred distributor"
          classes="w-100 mr4"
        />

        <Field
          name="currency"
          handleChange={handleChange}
          component={Select}
          options={config.currencies.map((currency) => currency.value)}
          validate={[validator.required]}
          label="Currency"
          placeholder="Add client's currency"
          helperText="Important: Note that if you change the currency, you would also need to 
          update the prices under custom equipments, special offers to reflect the new currency."
          type="select"
          autocomplete="currency"
          classes="w-100"
        />
      </div>
      <div className="flex flex-wrap mt3">
        <Field
          name="customerComments"
          component={Textarea}
          label="Customer comments"
          placeholder="Customer request comments"
          type="textarea"
          validate={[]}
          autocomplete="comments"
          classes="flex-auto"
          style={{ minHeight: '300px' }}
          disabled
        />
      </div>
    </fieldset>
  </Panel>
)

export default CustomerInformation
