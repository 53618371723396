export const getUsersByRole = (state, role) => {
  state.users.result.filter(uid => {
    if (state.users.entities[uid].role === role) {
      return state.users.entities[uid]
    }
    return null
  })
}

export const getAllUsers = state => state.users
export const getIsUserFetching = state => state.users._isFetching
