import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import firebase from 'firebase/app'
import 'firebase/auth'
import { Button, Intent } from '@blueprintjs/core'
// Store
import { getUser } from '../../store/User/selectors'
// Components
import Section from '../../components/Section/Section'

class Login extends Component {
  constructor(props) {
    super(props)
    // this.state = {errorCode: null, errorMessage: null}
    this.handleSignout = this.handleSignout.bind(this)
  }

  static propTypes = {
    user: PropTypes.object,
  }

  componentDidMount() {}

  handleSignout(values) {
    firebase
      .auth()
      .signOut()
      .then(() => {
        // Sign-out successful.
      })
      .catch(error => {
        console.error(error)
      })
  }

  render() {
    // Check if the user has been authenticated
    const { user } = this.props

    const { email, displayName, photoURL } = user

    return (
      <Section>
        <img alt={displayName} src={photoURL} width="60px" height="60px" className="fl mr3" />
        <h1 className="ma3">Hi, {displayName}</h1>
        <hr />
        <p>Email: {email || 'No email'}</p>
        <Button icon="user" onClick={this.handleSignout} intent={Intent.PRIMARY}>
          Sign out
        </Button>
      </Section>
    )
  }
}

// eslint-disable-next-line
const mapStateToProps = state => ({
  user: getUser(state),
})

export default connect(mapStateToProps, {})(Login)
