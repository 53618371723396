import { z } from 'zod';

const colorTypeSchema = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  taxonomy: z.string(),
  acf: z.object({
    hex: z.string(),
    image: z.union([z.boolean(), z.string().nullable()]),
  }),
});

export type ColorOption = z.infer<typeof colorTypeSchema>;

export default z.array(colorTypeSchema);
