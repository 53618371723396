interface Props {
  currency: string;
  active: boolean;
  includeVat: boolean;
  info?: string;
  icon: (props: any) => JSX.Element;
  onCurrencySelect: (currency: string) => void;
}

export function CurrencySelectorItem(props: Props) {
  const CurrencyIcon = props.icon;

  return (
    <>
      <button
        className="w-100 pointer b--none flex items-center pv2 justify-between f5 geo-semibold"
        style={{
          backgroundColor: props.active ? '#d8ecf0' : 'white',
        }}
        onClick={() => props.onCurrencySelect(props.currency)}
      >
        <div className="flex items-center">
          <CurrencyIcon width={28} height={28} />
          <span className="ml2">
            {props.currency} {props.includeVat ? 'incl. VAT' : 'excl. VAT'}
          </span>
        </div>

        {props.active && (
          <svg xmlns="http://www.w3.org/2000/svg" width={16} height={12} fill="none">
            <path
              fill="#fb5515"
              d="M15.66 1.262 4.922 12 0 7.078l1.262-1.261 3.66 3.65L14.398 0l1.262 1.262z"
            />
          </svg>
        )}
      </button>

      {props.active && props.info && (
        <div style={{ backgroundColor: '#ecf5f7', padding: '1rem 1rem', fontSize: '16px' }}>
          <p className="mb0 black geo-regular">{props.info}</p>
        </div>
      )}
    </>
  );
}
