import { ReactNode } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { lightenDarkenColor } from '../../helpers/colorTransforms';
import { LeftArrow } from '../Icons/LeftArrow';
import { RightArrow } from '../Icons/RightArrow';

interface WrapperProps {
  disabled?: boolean;
}

const Wrapper = styled(Link)<WrapperProps>`
  @media (min-width: 30em) {
    min-width: 200px;
  }
  background-color: ${(props) =>
    props.disabled ? props.theme.primaryLightGrey : props.theme.primaryOrange};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  transition: all 0.1s ease-in;
  min-width: 90px;
  height: 35px;
  margin: auto;
  &:hover {
    background-color: ${(props) => lightenDarkenColor(props.theme.primaryOrange, 20)};
    text-decoration: none;
    color: #fff;
    text-decoration: none;
  }
`;

interface Props {
  disabled?: boolean;
  direction?: 'left' | 'right';
  children?: ReactNode;
  to: string;
}

function NextLink(props: Props) {
  const { children, to, disabled, direction, ...rest } = props;
  return (
    <Wrapper
      disabled={disabled}
      to={to}
      {...rest}
      className="flex flex-auto items-center justify-center w-25 tc db geo-semibold f5 white hover:white hover-no-underline no-underline ph1 ph2-ns pv1 pointer input-reset ttu"
    >
      {direction === 'left' && <LeftArrow className="mr1" width={14} title="" />}
      {children}
      {direction === 'right' && <RightArrow className="ml1" width={14} title="" />}
    </Wrapper>
  );
}

export default NextLink;
