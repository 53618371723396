import { ReactNode } from 'react';

type Props =
  | {
      hex: string;
      classes?: string;
      opacity?: number;
      children?: ReactNode;
    }
  | {
      type: 'orange';
      children?: ReactNode;
    };

export function Tag(props: Props) {
  if ('type' in props) {
    if (props.type === 'orange') {
      return (
        <div>
          <div
            className="dib mb1 white br--right pointer-events-none"
            style={{ background: '#fb5515', opacity: 1 }}
          >
            <dt
              style={{ fontSize: '12px' }}
              className="p-3 geo-regular ph1 pv1 mt0 lh-solid fw-400"
            >
              {props.children}
            </dt>
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <div>
      <div
        className={`black dib mb1 ${props.classes ?? ''}`}
        style={{ background: props.hex, opacity: props.opacity }}
      >
        <dt style={{ fontSize: '12px' }} className="p-3 geo-regular ph1 pv1 mt0 lh-solid">
          {props.children}
        </dt>
      </div>
    </div>
  );
}
