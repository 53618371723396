import PropTypes from 'prop-types';
import React from 'react';
import Moment from 'moment'

const Date = ({ data }) => (
  <span>
    {Moment().diff(Moment(data), 'days') > 0 ? Moment(data).format('MMM DD, YYYY') : Moment(data).fromNow()}
  </span>
)

Date.propTypes = {
  data: PropTypes.number
}

export default Date
