import { SVGProps } from 'react';

const SvgSwedenFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="sweden-flag-icon_svg__Lager_1"
    x={0}
    y={0}
    style={
      {
        // This setting is deprecated, see: https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/enable-background
        enableBackground: 'new 0 0 800 800',
      } as any
    }
    viewBox="0 0 800 800"
    {...props}
  >
    <style>
      {'.sweden-flag-icon_svg__st0{fill:#006aa7}.sweden-flag-icon_svg__st1{fill:#fecc00}'}
    </style>
    <path
      d="M344 754.3c19.5 3.3 39.5 5 59.9 5 173.4 0 318-123.2 351.1-286.9H344v281.9zM52.8 472.4c18.6 91.9 72.3 171.1 146.6 222.8V472.4H52.8zM199.4 107c-74.3 51.7-128 130.9-146.6 222.8h146.6V107zM755 329.8C721.9 166.1 577.3 42.9 403.9 42.9c-20.4 0-40.4 1.7-59.9 5v281.9h411z"
      className="sweden-flag-icon_svg__st0"
    />
    <path
      d="M199.4 329.8H52.8c-4.7 23-7.1 46.9-7.1 71.3s2.4 48.3 7.1 71.3h146.6V329.8zM755 329.8H344v142.6h411c4.7-23 7.1-46.9 7.1-71.3s-2.4-48.3-7.1-71.3zM344 329.8V47.9c-53.1 8.9-102.2 29.5-144.6 59.1v222.8H344zM199.4 472.4v222.8c42.4 29.5 91.5 50.1 144.6 59.1V472.4H199.4zM199.4 329.8H344v142.6H199.4z"
      className="sweden-flag-icon_svg__st1"
    />
  </svg>
);

export default SvgSwedenFlagIcon;
