import React, { useState } from 'react'
import { motion } from 'framer-motion'
import PlusIcon from './PlusIcon'

const variants = {
  open: { opacity: 1, height: 'auto' },
  collapsed: { opacity: 0, height: 0 },
}

const AccordionItem = props => {
  const { children, heading } = props

  const [open, setOpen] = useState(false)

  const toggleAccordion = () => {
    setOpen(!open)
  }

  return (
    <div>
      <div onClick={toggleAccordion} className="pointer flex justify-between items-center">
        <h3 className="geo-regular f5 mb0 mt0">{heading}</h3>
        <PlusIcon></PlusIcon>
      </div>

      <motion.div
        className="overflow-hidden"
        initial="hidden"
        animate={open ? 'open' : 'collapsed'}
        variants={variants}
      >
        <div className="mt3">{children}</div>
      </motion.div>
    </div>
  )
}

export default AccordionItem
