import * as Sentry from '@sentry/browser';

export function log(msg: string, context?: { [key: string]: any }): void {
  console.log(msg, context);

  Sentry.captureMessage(`${msg} Context: ${JSON.stringify(context)}`);

  Sentry.captureEvent({
    event_id: 'logged-message',
    message: msg,
    extra: context,
  });
}
