import * as Sentry from '@sentry/browser';
import { config } from '../config';

interface GetOptionParams {
  boatModel: string;
  _embed?: boolean;
}

export type OptionType =
  | 'boats'
  | 'drivetrains'
  | 'colorareas'
  | 'seats'
  | 'tops'
  | 'equipments'
  | 'color'
  | 'addons';

// type SchemaType = {
//   [key in OptionType]: z.infer<(typeof schemas)[key]>;
// };

export async function getOptionByType(type: OptionType, params: GetOptionParams): Promise<unknown> {
  // ): Promise<SchemaType[typeof type]> {
  const queryParams = new URLSearchParams({
    per_page: type === 'equipments' ? '1000' : '100', // Some of the options can have more than 100 elements
    'filter[boatmodel]': params.boatModel,
    'filter[orderby]': 'title',
    order: 'asc',
  });

  if (params._embed) {
    queryParams.set('_embed', params._embed ? '1' : '0');
  }

  const url = `${config.wordpressRootUrl}/wp-json/wp/v2/${type}?${queryParams}`;

  const res = await fetch(url, {
    credentials: 'include',
  });

  /**
   * Check the headers; we expect the WP per page limit to be high enough to
   * allow us to fetch all elements. If this header isn't 1, it means we didn't
   * get all elements.
   */
  const totalPages = res.headers.get('X-wp-totalpages');
  if (totalPages && totalPages !== '1' && totalPages !== '0') {
    console.error(`Error: X-wp-totalpages header value is ${totalPages}, expected 1`);
    Sentry.captureMessage('Error: WP API call returned multiple pages', {
      tags: {
        totalPages,
        url,
      },
    });
  }

  const data = await res.json();
  return data;
}
