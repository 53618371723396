const areEquipmentRequirementsMet = (equipment, build) => {
  const hasRequirements = equipment.acf.requires && equipment.acf.requires.length !== 0
  if (hasRequirements) {
    const requireables = [
      parseInt(build.seat, 10),
      parseInt(build.top, 10),
      parseInt(build.drivetrain, 10),
    ]
    const found = equipment.acf.requires.some(r => requireables.includes(r))
    if (found) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export default areEquipmentRequirementsMet
