import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const Breadcrumb = ({ id, skeleton }) => {
  return (
    <ul className="bp3-breadcrumbs">
      <li>
        <Link className="bp3-breadcrumbs" to="/">
          Orders
        </Link>
      </li>
      <li>
        <span className={`${skeleton && 'bp3-skeleton'} bp3-breadcrumb bp3-breadcrumb-current`}>
          {id}
        </span>
      </li>
    </ul>
  )
}

Breadcrumb.propTypes = {
  id: PropTypes.string.isRequired
}

export default Breadcrumb
