import PropTypes from 'prop-types'
import React from 'react'

const Input = ({
  autocomplete,
  placeholder,
  classes,
  input,
  label,
  type,
  disabled,
  meta: { touched, error, warning },
  ...rest
}) => (
  <div className={classes}>
    <label className="bp3-label" htmlFor={input.name}>
      {label && label}
      <textarea
        {...input}
        className={`bp3-fill bp3-input ${error && touched && 'bp3-intent-danger'}`}
        type={type}
        placeholder={placeholder}
        autoComplete={autocomplete}
        disabled={disabled}
        {...rest}
      />
      {touched && error && <span className="mt1 db red">{error}</span>}
    </label>
  </div>
)

Input.propTypes = {
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  classes: PropTypes.string,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
}

export default Input
