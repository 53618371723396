import * as Sentry from '@sentry/browser';
import { config } from '../config';
import schema from './schemas/boats';

export interface BoatDefinition {
  id: number;
  title: string | null;
}

export type BoatDefinitions = Array<BoatDefinition>;

/**
 * Get a list of boat definitions and a tiny subset of details about each boat.
 *
 * This (as of 2023-08-03) is only used in the admin section, the orders list to
 * display the correct boat name for each order.
 *
 * @returns A list of boat definitions, with a subset of details.
 */
export async function getBoatDefinitions(): Promise<BoatDefinitions> {
  const url = `${config.wordpressRootUrl}/wp-json/wp/v2/boats?per_page=100&_embed=0&filter[boatmodel]=`;

  const res = await fetch(url, {
    credentials: 'include',
  });

  const data = await res.json();

  const validatedData = schema.safeParse(data);

  if (!validatedData.success) {
    console.error('API validation error:', validatedData.error.issues);
    Sentry.captureMessage('Error: Validation of API return object failed', {
      tags: {
        url,
        issues: JSON.stringify(validatedData.error.issues),
      },
    });
    return []; // Return unvalidated data as fallback
  }

  return validatedData.data
    .filter((b) => b.id)
    .map((b) => ({
      id: b.id,
      title: b.title?.rendered ?? null,
    }));
}
