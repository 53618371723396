import React from 'react'
import styled from 'styled-components'
import LogoResponsive from '../LogoResponsive'

const TopMenu = styled.section`
  width: 100%;
  height: 60px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  z-index: 99999;
`
const FakeMenu = styled.section`
  width: 100%;
  height: 60px;
  background-color: #fff;
`

const Menu = ({ children }) => {
  return (
    <>
      <TopMenu>
        <div className="flex content-center w-100 h-100 items-center">
          <a className="lh-solid h-100 gradient-grey flex items-center" href="https://www.goldfishboat.com" title="Home">
            <LogoResponsive></LogoResponsive>
          </a>
          {children}
        </div>
      </TopMenu>
      <FakeMenu></FakeMenu>
    </>
  )
}

export default Menu
