import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Button } from '@blueprintjs/core'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import Select from '../../components/Select/Select'
import DragHandle from '../../components/DragHandle/DragHandle'

const SortableItem = SortableElement(({ item, itemIndex, options, handleRemoveItem, type }) => (
  <li className="flex flex-wrap items-center justify-between">
    <Field
      name={`${item}.${type}`}
      label="Equipment"
      component={Select}
      placeholder="Select an equipment"
      type="select"
      options={options}
      classes="flex-auto mr4"
      validate={[]}
      // handleChange={handleChange}
    />
    <div className="pt2 flex items-center bp3-button-group bp3-minimal">
      <Button type="button" icon="delete" data-index={itemIndex} onClick={handleRemoveItem} />
      <DragHandle />
    </div>
  </li>
))

const SortableList = SortableContainer(
  ({ handleChange, handleRemoveItem, options, fields, type }) => (
    <div>
      {fields.map((item, index) => (
        <SortableItem
          key={`item-${index}`}
          handleRemoveItem={handleRemoveItem}
          index={index}
          itemIndex={index}
          item={item}
          options={options}
          type={type}
        />
      ))}
    </div>
  )
)

class EquipmentLineItem extends Component {
  constructor(props) {
    super(props)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.handleRemoveItem = this.handleRemoveItem.bind(this)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.fields.move(oldIndex, newIndex)
  }

  handleRemoveItem = (e) => {
    this.props.fields.remove(e.currentTarget.dataset.index)
  }

  render() {
    const {
      options,
      fields,
      type,
      meta: { touched, error },
    } = this.props

    const sortedOptionsAlfabetical = options?.sort((a, b) => {
      if (a?.displayName < b?.displayName) {
        return -1
      }
      if (a?.displayName > b?.displayName) {
        return 1
      }
      return 0
    })

    return (
      <ul className="list pl0">
        <SortableList
          lockAxis={'y'}
          useDragHandle
          handleRemoveItem={this.handleRemoveItem}
          options={sortedOptionsAlfabetical}
          fields={fields}
          onSortEnd={this.onSortEnd}
          type={type}
        />
        <li className="flex bp3-button-group">
          <Button icon="add" type="button" onClick={() => fields.push({})}>
            Add equipment
          </Button>
          {touched && error && <span>{error}</span>}
        </li>
      </ul>
    )
  }
}

EquipmentLineItem.propTypes = {
  options: PropTypes.array,
  fields: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
}

export default EquipmentLineItem
