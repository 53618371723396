import { PricesKey } from '../api/schemas/shared';
import { config } from '../config';

/**
 * getCurrency - function that returns the currency information based on the currency code.
 * @param currency - The currency code to search for in the configuration.
 * @returns The currency object that matches the currency code provided.
 * @throws {Error} If the currency code provided is not part of the configuration currencies, an error is thrown.
 */

export function getCurrency(currency: string) {
  if (!currency) {
    return;
  }

  try {
    const findCurrency = config.currencies.find((opt) => opt.value === currency);

    if (findCurrency) {
      return findCurrency;
    }

    throw new Error(
      `Currency you've requested (${currency}) is not found! Please make sure the currency you provided to the function is part of the config currencies`
    );
  } catch (e) {
    console.error(e);
  }
}

export function getCurrencyKey(currency: string) {
  const currencyDefinition = getCurrency(currency);
  return currencyDefinition?.label as unknown as PricesKey | undefined;
}
