import PropTypes from 'prop-types'
import React from 'react'

const renderOption = (option, index) => {
  return (
    <option key={index} value={option.displayName}>
      {option.displayName
        ? option.displayName
        : option.displayName === ''
        ? `Option id ${option.id}`
        : option}
    </option>
  )
}

const SelectCountry = ({
  classes,
  input,
  label,
  type,
  options,
  autocomplete,
  placeholder,
  disabled = false,
  meta: { touched, error, warning },
}) => (
  <div className={classes}>
    <label className="bp3-label" htmlFor={input && input.name}>
      <div className="flex justify-between">{label}</div>
      <div className="bp3-select">
        <select
          {...input}
          className={`bp3-fill ${error && touched && 'bp3-intent-danger'}`}
          autoComplete={autocomplete}
          onChange={(e) => {
            input.onChange(e)
          }}
          disabled={disabled}
        >
          <option disabled value="">
            {placeholder}
          </option>
          {options && options.map((option, index) => renderOption(option, index))}
        </select>
        {touched && error && <span className="mt1 db red">{error}</span>}
      </div>
    </label>
  </div>
)

SelectCountry.propTypes = {
  classes: PropTypes.string,
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  input: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string,
  }),
}

export default SelectCountry
