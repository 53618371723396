import { handleActions, combineActions } from 'redux-actions';
import { syncBuildSuccess } from '../Builds/actions';
import {
  setLoading,
  setError,
  fetchCommonOptionsSuccess,
  fetchCommonOptionsRequest,
  fetchCommonOptionsFailure,
} from './actions';
import {
  syncOrderSuccess,
  fetchOrderFailure,
  fetchOrdersFailure,
  fetchOrderSuccess,
  fetchOrdersSuccess,
} from '../Orders/actions';
import { getCurrencyPreference } from '../../helpers/currencyPreferenceHelpers';
import { GlobalOptions } from '../../api/getGlobalOptions';

export type CommonState = {
  loading: boolean;
  saving: boolean;
  error: boolean | string;
  userCurrency: string;
  estimatedDeliveryTime: number;
  requestBuildFaqs?: Array<{ title: string; body: string }>;
  options?: GlobalOptions;
};

const INITIAL_STATE: CommonState = {
  loading: true,
  saving: false,
  error: false,
  userCurrency: getCurrencyPreference(),
  estimatedDeliveryTime: 90,
};

export const common = handleActions(
  {
    [setLoading as any]: (state: CommonState, action: any): CommonState => {
      return {
        ...state,
        loading: action.payload,
      };
    },

    [setError as any]: (state: CommonState, action: any): CommonState => {
      return {
        ...state,
        error: action || 'Something went wrong.',
      };
    },

    [fetchCommonOptionsSuccess as any]: (state: CommonState, action: any): CommonState => {
      return {
        ...state,
        loading: false,
        estimatedDeliveryTime: Number(action.result.days),
        requestBuildFaqs: action.result.faqs,
        options: action.result,
      };
    },

    [fetchCommonOptionsRequest as any]: (state: CommonState, action: any): CommonState => {
      return {
        ...state,
        loading: true,
      };
    },

    [fetchCommonOptionsFailure as any]: (state: CommonState, action: any): CommonState => {
      return {
        ...state,
        loading: false,
        error:
          'Could not fetch global settings. This might be a network issue, please try again later.',
      };
    },

    [combineActions(syncBuildSuccess, syncOrderSuccess) as any](state: CommonState, action: any) {
      return {
        ...state,
        userCurrency: action.payload ? action.payload.currency : getCurrencyPreference(),
        error: false,
      };
    },

    [combineActions(fetchOrderFailure, fetchOrdersFailure) as any](
      state: CommonState,
      action: any
    ) {
      return {
        ...state,
        error: 'Something went wrong. Please contact Goldfish Boat Support.',
      };
    },

    [combineActions(fetchOrderSuccess, fetchOrdersSuccess) as any](
      state: CommonState,
      action: any
    ) {
      return {
        ...state,
        error: false,
      };
    },
  },
  INITIAL_STATE
);
