import { z } from 'zod';
import * as s from './shared';

const drivetrainTypeSchema = s.wpOptionEntrySchema.extend({
  boatmodel: z.array(z.number()),
  acf: z.object({
    display_name: z.string(),
    motor_type_new: z.string(),
    price: z.union([z.string(), z.number()]),
    price_eur: z.union([z.string(), z.number()]),
    price_sek: z.union([z.string(), z.number()]),
    price_usd: z.union([z.string(), z.number()]),
    intro: z.string(),
    stats: z.array(
      z.object({
        title: z.string(),
        value: z.string(),
      })
    ),
    meta: z.array(
      z.object({
        title: z.string(),
        value: z.string(),
      })
    ),
    shapediver_property_id: z.string(),
    shapediver_option_id: z.string(),
  }),
  prices: s.pricesSchema,
});

export type DrivetrainOption = z.infer<typeof drivetrainTypeSchema>;

export default z.array(drivetrainTypeSchema);
