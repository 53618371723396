import { Fragment, useState } from 'react';
import { Dialog } from '@reach/dialog';

import TotalBuildPrice from '../../../components/TotalBuildPrice/TotalBuildPrice';

import { getCurrentTotalCost } from '../../../helpers/getCurrentTotalCost';
import { getCurrency } from '../../../helpers/getCurrency';
import { setLocalStorageCurrency } from '../../../helpers/currencyPreferenceHelpers';

import { config } from '../../../config';

import { CurrencySelectorItem } from './CurrencySelectorItem';
import ConfirmButton from '../../../components/ConfirmButton/ConfirmButton';

type InterimDrivetrainsType = any;
type InterimTopsType = any;
type InterimSeatsType = any;
type InterimColorareasType = any;
type InterimEquipmentsType = any;
type InterimAddonsType = any;
type InterimBuildType = any;

interface Props {
  drivetrains: InterimDrivetrainsType;
  tops: InterimTopsType;
  seats: InterimSeatsType;
  colorareas: InterimColorareasType;
  equipments: InterimEquipmentsType;
  addons: InterimAddonsType;
  build: InterimBuildType;
  userCurrency: string;
  handleSelect: any;
  isNewBuild: boolean;
}

export function CurrencySelector(props: Props) {
  const {
    drivetrains,
    tops,
    seats,
    colorareas,
    equipments,
    addons,
    build,
    userCurrency,
    handleSelect,
    isNewBuild,
  } = props;
  const activeCurrency = getCurrency(userCurrency);

  const [showDialog, setShowDialog] = useState(() => isNewBuild);

  const open = () => setShowDialog(true);
  const close = () => setShowDialog(false);

  const handleCurrencyUpdate = (currency: string) => {
    handleSelect({
      currentTarget: { dataset: { name: 'currency', value: currency } },
    });
    setLocalStorageCurrency(currency);
    // close(); // Avoid closing the dialog in order to see the info message
  };

  return (
    <div>
      <button
        className="p-1 input-reset bt-0 bl-0 br-0 ph0 pointer geo-semibold bb border-green dib bt-0 br-0 bl-0"
        onClick={open}
      >
        <span className="geo-semibold f5">{activeCurrency?.value}</span>{' '}
        <TotalBuildPrice
          className="geo-semibold f5 pointer-events-none"
          from={
            getCurrentTotalCost(
              drivetrains,
              tops,
              seats,
              colorareas,
              equipments,
              build,
              userCurrency,
              addons
            ) - 20000
          }
          to={getCurrentTotalCost(
            drivetrains,
            tops,
            seats,
            colorareas,
            equipments,
            build,
            userCurrency,
            addons
          )}
        />
        <span className="f6 geo-semibold">
          {activeCurrency?.includeVat ? 'incl. VAT' : 'excl. VAT'}
        </span>
      </button>
      <Dialog
        className="pa3"
        style={{ width: '360px', zIndex: 9999 }}
        isOpen={showDialog}
        onDismiss={close}
        aria-label="Select currency"
      >
        <div className="relative">
          <h3 className="geo-semibold f4 mt0 mb3">Show prices in</h3>
          <button
            className="input-reset pointer absolute top-0 right-0 b--none nt3 nr3 pa0 bg-transparent"
            style={{ width: '20px', height: '20px' }}
            onClick={close}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <circle cx="10" cy="10" r="10" fill="#fb5515"></circle>
              <path
                fill="#fff"
                stroke="#fff"
                strokeWidth="0.25"
                d="M6.72 13.967l.087.088.089-.088 3.175-3.175 3.175 3.175.089.088.088-.088.544-.544.088-.088-.088-.089-3.175-3.175 3.175-3.175.088-.089-.088-.088-.544-.544-.088-.088-.089.088-3.175 3.175-3.175-3.175-.088-.088-.089.088-.544.544-.088.088.088.089L9.35 10.07l-3.175 3.175-.088.089.088.088.544.544z"
              ></path>
            </svg>
          </button>

          {/* <div className="w-100 bb b--light-gray"></div> */}

          {config.currencies.map((currency, idx) => {
            const isLast = config.currencies.length - 1 === idx;
            return (
              <Fragment key={currency.value}>
                <CurrencySelectorItem
                  currency={currency.value}
                  info={currency.info}
                  onCurrencySelect={handleCurrencyUpdate}
                  icon={currency.icon}
                  includeVat={currency.includeVat}
                  active={userCurrency === currency.value}
                />
                {!isLast && <div className="w-100 bb b--light-gray"></div>}
              </Fragment>
            );
          })}

          <ConfirmButton style={{ margin: '32px 0 0 auto' }} onClick={close}>
            Close
          </ConfirmButton>
        </div>
      </Dialog>
    </div>
  );
}
