import { handleActions, combineActions } from 'redux-actions'

import {
  createBuildRequest,
  createBuildSuccess,
  createBuildFailure,
  fetchBuildRequest,
  fetchBuildSuccess,
  fetchBuildFailure,
  syncBuildSuccess
} from './actions'

export const builds = handleActions({
  [combineActions(createBuildRequest, fetchBuildRequest)] (state, action) {
    return {
      ...state,
      _isSaving: true
    }
  },
  [combineActions(createBuildSuccess, fetchBuildSuccess)] (state, action) {
    return {
      ...state,
      _isSaving: false
    }
  },
  [combineActions(createBuildFailure, fetchBuildFailure)] (state, action) {
    return {
      ...state,
      _error: action,
      _isSaving: false
    }
  },
  [syncBuildSuccess]: (state, action) => ({
    ...state,
    [action.payload.id]: { ...action.payload }
  })
}, {})
