import { getCurrency } from './getCurrency';

const CURRENCY_LOCALSTORAGE_KEY = 'currency';

export function setLocalStorageCurrency(currency: string) {
  localStorage.setItem(CURRENCY_LOCALSTORAGE_KEY, currency);
}

export function getCurrencyPreference(): string {
  const localStorageCurrency = localStorage.getItem(CURRENCY_LOCALSTORAGE_KEY);
  const browserLanguage = navigator.language;

  if (localStorageCurrency) {
    const currency = getCurrency(localStorageCurrency);

    if (currency) {
      return currency.value;
    }
  }

  if (
    browserLanguage === 'nb' ||
    browserLanguage === 'no' ||
    browserLanguage === 'nn' ||
    browserLanguage === 'nb-NO'
  ) {
    return 'NOK';
  } else if (browserLanguage === 'sv-SE' || browserLanguage === 'sv') {
    return 'SEK';
  } else if (browserLanguage === 'en-US' || browserLanguage === 'en') {
    return 'USD';
  } else {
    return 'EUR';
  }
}
