import PropTypes from 'prop-types';
import React from 'react';
import { InputGroup, Tag } from '@blueprintjs/core'

const CustomInputGroup = ({
  autocomplete,
  placeholder,
  classes,
  input,
  label,
  type,
  required,
  currency,
  meta: {
    touched,
    error,
    warning
  }
}) => (
  <div className={classes}>
    <label className='bp3-label roboto-regular' htmlFor={input.name}>
      {label}
      <InputGroup
        rightElement={<Tag className='bp3-minimal'>{currency}</Tag>}
        className={`roboto-regular ${error && touched && 'bp3-intent-danger'}`}
        type={type}
        placeholder={placeholder}
        autoComplete={autocomplete}
        required={required}
        {...input}
      />
      {touched && (error && <span className='mt1 db red roboto-regular'>{error}</span>)}
    </label>
  </div>
)

CustomInputGroup.propTypes = {
  autocomplete: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  classes: PropTypes.string,
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool.isRequired,
    error: PropTypes.string,
    warning: PropTypes.string
  })
}

export default CustomInputGroup
