import PropTypes from 'prop-types';
import React from 'react';
import { Radio, RadioGroup } from '@blueprintjs/core'

const ModelSelector = ({ onChange, selectedValue, options }) => (
  <RadioGroup className='flex flex-wrap' onChange={onChange} selectedValue={selectedValue}>
    {options.result.map((option, index) => (
      <Radio
        className='bp3-inline flex-auto w5'
        key={index}
        label={options.entities[option].title.rendered}
        value={`${option}`}
      />
    ))}
  </RadioGroup>
)

ModelSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ]),
  options: PropTypes.object.isRequired
}

export default ModelSelector
