import React from 'react'
import { NavLink } from 'react-router-dom'

const Navbar = () => (
  <nav className='bp3-navbar bp3-dark'>
    <div className='bp3-navbar-group bp3-align-left'>
      <div className='bp3-navbar-heading'>Goldfish Sales</div>
    </div>
    <div className='bp3-navbar-group bp3-align-right'>
      <NavLink to='/' activeClassName='bp3-active' className='bp3-button bp3-minimal bp3-icon-document'>Orders</NavLink>
      <span className='bp3-navbar-divider' />
      <NavLink to='/user/' activeClassName='bp3-active' className='bp3-button bp3-minimal bp3-icon-user' />
    </div>
  </nav>
)

// const Navbar = () => (
//   <nav className='bp3-navbar bp3-dark'>
//     <div className='bp3-navbar-group bp3-align-left'>
//       <div className='bp3-navbar-heading'>Goldfish Sales</div>
//     </div>
//     <div className='bp3-navbar-group bp3-align-right'>
//       <Link activeClassName='bp3-active' activeOnlyWhenExact to='/' className='bp3-button bp3-minimal bp3-icon-dashboard'>Dashboard</Link>
//       <Link activeClassName='bp3-active' to='/orders' className='bp3-button bp3-minimal bp3-icon-document'>Orders</Link>
//       <span className='bp3-navbar-divider' />
//       <Link activeClassName='bp3-active' activeOnlyWhenExact to='/user' className='bp3-button bp3-minimal bp3-icon-user' />
//       {/*
//       <button className='bp3-button bp3-minimal bp3-icon-notifications' />
//       <button className='bp3-button bp3-minimal bp3-icon-cog' /> */}
//     </div>
//   </nav>
// )

export default Navbar
