import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  box-shadow: 0 4px 19px 0 rgba(0,0,0,0.11);
`
const Card = ({children, large, style}) => (
  <div className={`mb4 mt3 ph3 ${!large && 'flex w-50-m w-33-l'}`}>
    <Wrapper className='bg-white br2 relative w-100' style={style}>
      {children}
    </Wrapper>
  </div>
)

export default Card
