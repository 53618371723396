import { createActions, createAction } from 'redux-actions';

import { getGlobalOptions } from '../../api/getGlobalOptions';

export const setLoading = createAction('SET_LOADING');
export const setError = createAction('SET_ERROR');

export const { fetchCommonOptionsRequest, fetchCommonOptionsSuccess, fetchCommonOptionsFailure } =
  createActions(
    'FETCH_COMMON_OPTIONS_REQUEST',
    'FETCH_COMMON_OPTIONS_SUCCESS',
    'FETCH_COMMON_OPTIONS_FAILURE'
  );

export const getCommonOptions = () => {
  return (dispatch: any, getState: any) => {
    /**
     * If we already have an ongoing request to fetch
     * common options, just disregard this other one.
     */
    if (getState().common.loading) {
      return;
    }

    dispatch({
      types: [
        fetchCommonOptionsRequest().type,
        fetchCommonOptionsSuccess().type,
        fetchCommonOptionsFailure().type,
      ],
      promise: getGlobalOptions(),
    });
  };
};
