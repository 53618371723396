import { useEffect, useState } from 'react';

interface DevelopmentSettings {
  useShapediverV3?: boolean;
  showDebuggingMessages?: boolean;
}

let settings: DevelopmentSettings = JSON.parse(
  localStorage.getItem('developmentSettings') ?? 'null'
) ?? {
  useShapediverV3: false,
  showDebuggingMessages: false,
};

function updateSettings(newSettings: DevelopmentSettings) {
  settings = { ...settings, ...newSettings };
  localStorage.setItem('developmentSettings', JSON.stringify(settings));
  return settings;
}

export function getDevelopmentSettings(): DevelopmentSettings {
  return settings;
}

/**
 *
 * pub/sub
 *
 */
type Listener = (devSettings: DevelopmentSettings) => void;

const subscribers: Array<Listener> = [];

function subscribe(fn: Listener) {
  subscribers.push(fn);
  return () => {
    const index = subscribers.indexOf(fn);
    if (index > -1) {
      subscribers.splice(index, 1);
    }
  };
}

function notify(devSettings: DevelopmentSettings) {
  subscribers.forEach((fn) => fn(devSettings));
}

/**
 *
 * hook
 *
 */
export function useGlobalDevelopmentSettings(): [
  DevelopmentSettings,
  (update: Partial<DevelopmentSettings>) => void
] {
  const [hookState, setHookState] = useState(settings);

  useEffect(
    () =>
      subscribe((devSettings) => {
        setHookState(devSettings);
      }),
    []
  );

  function setDevSettings(newSettings: Partial<DevelopmentSettings>) {
    const settings = updateSettings(newSettings);
    notify(settings);
  }

  return [hookState, setDevSettings];
}
