import { z } from 'zod';

/**
 * We allow for missing images (literal false/null)
 */
export const wpImageSchema = z.union([
  z.literal(false),
  z.literal(null),
  z.object({
    id: z.number(),
    title: z.string(),
    filename: z.string(),
    url: z.string(),
  }),
]);

export const wpOptionEntrySchema = z.object({
  id: z.number(),
  date: z.string(),
  date_gmt: z.string(),
  modified: z.string(),
  modified_gmt: z.string(),
  slug: z.string(),
  status: z.string(),
  type: z.string(),
  title: z.object({
    rendered: z.string(),
  }),
});

export const pricesSchema = z.object({
  price_nok: z.string(),
  price_eur: z.string(),
  price_usd: z.string(),
  price_sek: z.string(),
});

export type PricesKey = keyof z.infer<typeof pricesSchema>;

export const coordinatesSchema = z.object({
  x: z.string(), // float number in string, e.g. "0.000000"
  y: z.string(), // float number in string, e.g. "0.000000"
  z: z.string(), // float number in string, e.g. "0.000000"
});

export const shapediverPerspectiveSchema = z.object({
  position: coordinatesSchema,
  target: coordinatesSchema,
});

export interface BuildType {
  id: string;
  boat?: number | null;
  boatSlug?: string | null;
  boatmodel?: number | null;
  colors: {
    [key: number | string]: number | string;
  };
  equipmentLineItems?: null | Array<{
    equipment?: number | null;
  }>;
  addonLineItems?: null | Array<{
    addon?: number | null;
  }>;
  createdAt?: number | null;
  currency?: string | null;
  drivetrain?: number | null;
  seat?: number | null;
  top?: number | null;
  step?: string | null;
  updatedAt?: number | null;
  status?: string | null;
}

/**
 * Example payload for WP image object
 */
// {
//     "ID": 19193,
//     "id": 19193,
//     "title": "46-thumbnail",
//     "filename": "46-thumbnail.jpg",
//     "filesize": 131502,
//     "url": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail.jpg",
//     "link": "https://stg-goldfishboat-dev.kinsta.cloud/boats/46-bullet/46-thumbnail/",
//     "alt": "",
//     "author": "360",
//     "description": "",
//     "caption": "",
//     "name": "46-thumbnail",
//     "status": "inherit",
//     "uploaded_to": 18953,
//     "date": "2020-09-02 18:51:06",
//     "modified": "2020-09-02 18:51:06",
//     "menu_order": 0,
//     "mime_type": "image/jpeg",
//     "type": "image",
//     "subtype": "jpeg",
//     "icon": "https://stg-goldfishboat-dev.kinsta.cloud/wp-includes/images/media/default.png",
//     "width": 1440,
//     "height": 752,
//     "sizes": {
//       "thumbnail": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail-150x150.jpg",
//       "thumbnail-width": 150,
//       "thumbnail-height": 150,
//       "medium": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail-300x157.jpg",
//       "medium-width": 300,
//       "medium-height": 157,
//       "medium_large": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail.jpg",
//       "medium_large-width": 1440,
//       "medium_large-height": 752,
//       "large": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail-1200x627.jpg",
//       "large-width": 960,
//       "large-height": 502,
//       "1536x1536": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail.jpg",
//       "1536x1536-width": 1440,
//       "1536x1536-height": 752,
//       "2048x2048": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail.jpg",
//       "2048x2048-width": 1440,
//       "2048x2048-height": 752,
//       "slider-size": "https://stg-goldfishboat-dev.kinsta.cloud/wp-content/uploads/2020/08/46-thumbnail-450x300.jpg",
//       "slider-size-width": 450,
//       "slider-size-height": 300
//     }
// }
