import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const RouteWhenAuthorized = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route {...rest} render={props => (
    isAuthenticated ? (
      <Component {...props} />
    ) : (
      <Redirect to={{
        pathname: '/login/',
        state: { from: props.location }
      }} />
    )
  )} />
)

export default RouteWhenAuthorized
