import React from 'react'
import { Spinner } from '@blueprintjs/core'

const Loading = ({...rest}) => (
  <section className='flex items-center justify-center mw7 center h-100' {...rest}>
    <Spinner className='bp3-small' />
  </section>
)

export default Loading
