import { useEffect, useRef } from 'react';

interface Options {
  onPropertyChanged: (key: string, value: any, previousValue: any) => void;
  onPropertyRemoved: (key: string, previousValue: any) => void;
  onAfterChanges: () => void;
}

/**
 * This hook is used to track changes in the parameters object, and trigger
 * callbacks when they change.
 *
 * @param parameters
 * @param options
 */
export const useTrackChanges = (parameters: Record<string, any>, options: Options) => {
  const previous = useRef<Record<string, any>>({});

  useEffect(() => {
    let changes = false;

    for (const [key, value] of Object.entries(parameters)) {
      if (previous.current[key] !== value) {
        options.onPropertyChanged(key, value, previous.current[key]);
        changes = true;
      }
    }

    for (const key of Object.keys(previous.current)) {
      if (!(key in parameters)) {
        options.onPropertyRemoved(key, previous.current[key]);
        changes = true;
      }
    }

    if (changes) {
      options.onAfterChanges();
    }

    previous.current = { ...parameters };
  }, [parameters, options]);
};
