import React from 'react'

const LargeLogo = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 698.68 56.03">
      <path d="M607.48 1.11c-.2-.79.68-1.41 1.36-.95 5.26 3.55 10.51 7.11 15.77 10.66-4.18 2.83-8.36 5.66-12.55 8.48-1.53-6.06-3.06-12.13-4.58-18.19zm1.36 54.76c-.68.46-1.56-.16-1.36-.95 2.11-8.39 4.23-16.78 6.34-25.18 13.66-9.23 27.31-18.47 40.97-27.7a6.747 6.747 0 017.55 0c4.35 2.94 8.7 5.89 13.06 8.83-22.19 15-44.38 30-66.56 45zm88.66-30.08c1.57 1.06 1.57 3.38 0 4.45L662.34 54a6.747 6.747 0 01-7.55 0c-4.35-2.94-8.69-5.88-13.04-8.82 14.07-9.51 28.14-19.03 42.21-28.54 4.51 3.04 9.03 6.1 13.54 9.15zM550.28 9.7v15.6h-51.31V9.7h-8.45v38.37h8.45V33.75h51.31v14.32h8.44V9.7zm-78.35 15.65l-53.89.02h.01c-.79 0-1.42-.54-1.42-1.33v-5.76c0-.78.63-1.36 1.41-1.36h61.14V9.76l-62.7.02c-4.67-.02-8.34 3.87-8.34 8.52v5.76c0 4.71 3.82 8.53 8.53 8.53l53.89-.02c.77 0 1.42.55 1.42 1.33h-.01l.01 5.77c0 .8-.64 1.33-1.42 1.33h-61.24v7.17l62.61-.01c4.7 0 8.52-3.8 8.52-8.49v-5.78c0-4.7-3.82-8.54-8.52-8.54M144.92 9.75l-54.13-.01c-4.71 0-8.57 3.78-8.57 8.5l.01 21.42c0 4.71 3.85 8.46 8.56 8.46h54c4.72 0 8.47-3.77 8.47-8.49l.01-21.42c-.01-4.66-3.75-8.46-8.35-8.46m-.07 28.53c0 .73-.64 1.41-1.36 1.41H91.83c-.54 0-1.1-.47-1.1-1.04V19.37c0-.65.69-1.19 1.38-1.19l51.27.02c.78 0 1.47.66 1.47 1.44v18.64zm178.12 9.88V34.03h56.77v-7.95h-65.22v22.08zm56.77-38.39h-65.22v7.85l65.22.02zm9.83 0h8.44v38.39h-8.44zM29.24 25.36v7.2l34.58-.01v7.1c0 .8-.64 1.33-1.42 1.33l-52.5.03c-.79 0-1.42-.54-1.42-1.33V18.29c0-.78.63-1.36 1.41-1.36h61.14V9.76l-62.69.01C3.67 9.76 0 13.65 0 18.29v21.39c0 4.71 3.82 8.53 8.53 8.53l55.26-.07c4.7 0 8.52-3.8 8.52-8.49v-14.3l-43.07.01zm142.62 14.32V9.74h-8.5v38.45l61.77-.06v-8.45zm132.57-17.25c-.04-7-5.77-12.61-12.76-12.66h-56.83v.06h-.03v38.2h.03v.06h56.83c6.99-.05 12.72-5.66 12.76-12.66v-13zm-8.5 12.41c0 2.76-1.89 4.73-5.11 4.73h-47.49V18.19h47.49c3.23 0 5.11 1.97 5.11 4.73v11.92z"></path>
    </svg>
  )
}

const LogoResponsive = () => {
  return (
    <>
      <div className="mh5-ns mh4-m dn dib-l" style={{ width: '216px' }}>
        <LargeLogo></LargeLogo>
      </div>
      <div className="db dn-l flex items-center justify-center tc" style={{ width: '60px' }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 27 16"
          width="27px"
          heigh="16px"
        >
          <path
            fill="#1E1E1F"
            d="M.012.318a.255.255 0 01.39-.274l4.505 3.045L1.32 5.512.012.318zm.386 15.637a.255.255 0 01-.39-.274l1.813-7.19L13.534.58a1.931 1.931 0 012.157 0l3.732 2.52L.398 15.956zm25.345-8.59c.449.304.449.967 0 1.27-3.35 2.261-6.702 4.522-10.052 6.785a1.931 1.931 0 01-2.157 0c-1.242-.838-2.484-1.68-3.725-2.517 4.023-2.716 8.042-5.435 12.066-8.151 1.288.868 2.576 1.742 3.868 2.613z"
          ></path>
        </svg>
      </div>
    </>
  )
}

export default LogoResponsive
