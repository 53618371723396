import React from 'react'
import CountUp from 'react-countup'

const TotalBuildPrice = ({ from, to, className }) => (
  <CountUp
    className={className}
    start={from}
    end={to}
    duration={0.5}
    useEasing={false}
    suffix=" "
    separator=" "
    useGrouping
  />
)

TotalBuildPrice.defaultProps = {
  className: 'geo-semibold f5',
}

export default TotalBuildPrice
