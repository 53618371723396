import { createAction } from 'redux-actions'

export const setOptionsByBoatmodel = createAction('SET_OPTIONS_BY_BOATMODEL')

export const updateSelection = (boatmodel) => (dispatch, getState) => {
  const optionsToFilter = ['drivetrains', 'colorareas', 'seats', 'tops', 'equipments', 'addons']
  let filteredOptions = {}
  // Map over the options
  // eslint-disable-next-line
  optionsToFilter.map((option) => {
    // Set a key for each option
    filteredOptions = {
      ...filteredOptions,
      [option]: [],
    }
    // Filter the options cache for only items equal to selected boatmodel
    // eslint-disable-next-line
    getState().options[option].result.map((item) => {
      // eslint-disable-next-line
      getState().options[option].entities[item].boatmodel.find((model) => {
        if (model === boatmodel) {
          if (option === 'colorareas') {
            // Add the colors arrays if colorarea
            return filteredOptions[option].push({
              id: getState().options[option].entities[item].id,
              displayName: getState().options[option].entities[item].acf.display_name,
              colors: [...getState().options[option].entities[item].acf.colors],
            })
          }
          // Push id and display name for selects onto the option array
          return filteredOptions[option].push({
            id: getState().options[option].entities[item].id,
            displayName: getState().options[option].entities[item].acf.display_name,
            cost: getState().options[option].entities[item].acf.price, // Used for specialOffersLineItems and customLineItems
          })
        }
      })
    })
  })
  return dispatch(setOptionsByBoatmodel(filteredOptions))
}
