import React, { Component } from 'react'
import Helmet from 'react-helmet'
// Components
import Card from '../../components/Card/Card'
import Loading from '../../components/Loading/Loading'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount'
import OrderSummary from '../../components/Summary'
import Faq from '../../components/Faq'

class Summary extends Component {
  render() {
    const { build, estimatedDeliveryTime, match } = this.props

    // const boat = options.boats ? options.boats.entities[options.boats.result[0]] : false

    if (!build) {
      return (
        <section className="relative mw8 center pb5">
          <section className="h5 mv3">
            <Loading />
          </section>
          <Card large>
            <section className="ph3 ph4-ns pv4 h5">
              <div className="bp3-skeleton dib mv2">23 Tender with Evinrude 2-stroke 300BHP</div>
              <div className="bp3-skeleton dib mv2">23 Tender with Evinrude</div>
            </section>
          </Card>
        </section>
      )
    }

    // const showPrice = boat && boat.acf.boat_class === 'consumer'

    return (
      <section className="relative center pb5" style={{ maxWidth: '600px' }}>
        <ScrollToTopOnMount />
        <Helmet title="Review" />
        <section className="ph3 ph4-ns pv4">
          <OrderSummary
            match={match}
            build={build}
            estimatedDeliveryTime={estimatedDeliveryTime}
            handleNextClick={() => {}}
          />
          <Faq></Faq>
        </section>
      </section>
    )
  }
}

export default Summary
