import { createActions } from 'redux-actions';
import { getBoatDefinitions } from '../../api/getBoatDefinitions';
import { getAllOptionsForModel } from '../../api/getAllOptionsForModel';

export const {
  fetchAllOptionsRequest,
  fetchAllOptionsSuccess,
  fetchAllOptionsFailure,
  fetchOptionRequest,
  fetchOptionSuccess,
  fetchOptionFailure,
  fetchBoatDefinitionsRequest,
  fetchBoatDefinitionsSuccess,
  fetchBoatDefinitionsFailure,
} = createActions(
  'FETCH_ALL_OPTIONS_REQUEST',
  'FETCH_ALL_OPTIONS_SUCCESS',
  'FETCH_ALL_OPTIONS_FAILURE',
  'FETCH_OPTION_REQUEST',
  'FETCH_OPTION_SUCCESS',
  'FETCH_OPTION_FAILURE',
  'FETCH_BOAT_DEFINITIONS_REQUEST',
  'FETCH_BOAT_DEFINITIONS_SUCCESS',
  'FETCH_BOAT_DEFINITIONS_FAILURE'
);

export const fetchOptions = (boatModel: string = '') => {
  return {
    types: [
      fetchAllOptionsRequest().type,
      fetchAllOptionsSuccess().type,
      fetchAllOptionsFailure().type,
    ],
    promise: getAllOptionsForModel(boatModel),
  };
};

export const fetchBoatDefinitions = () => {
  return {
    types: [
      fetchBoatDefinitionsRequest().type,
      fetchBoatDefinitionsSuccess().type,
      fetchBoatDefinitionsFailure().type,
    ],
    promise: getBoatDefinitions(),
  };
};
