import { handleActions, combineActions } from 'redux-actions'
import {
  createOrderRequest,
  createOrderSuccess,
  createOrderFailure,
  saveOrderRequest,
  saveOrderSuccess,
  saveOrderFailure,
  fetchOrderRequest,
  fetchOrderSuccess,
  fetchOrderFailure,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  syncOrderSuccess,
  syncOrdersSuccess
} from './actions'

const INITIAL_STATE = {
  _isFetching: false,
  _isSaving: false,
  _error: false
}

export const orders = handleActions(
  {
    [combineActions(fetchOrderRequest, fetchOrdersRequest)](state, payload) {
      return { ...state, _isFetching: true }
    },
    [combineActions(fetchOrderFailure, fetchOrdersFailure, saveOrderFailure, createOrderFailure)](
      state,
      payload
    ) {
      return { ...state, _error: payload, _isFetching: false, _isSaving: false }
    },
    [createOrderSuccess]: (state, payload) => {
      return { ...state, _isSaving: false }
    },
    [combineActions(saveOrderRequest, createOrderRequest)](state, action) {
      return {
        ...state,
        _isSaving: true
      }
    },
    [saveOrderSuccess]: (state, action) => ({
      ...state,
      _isSaving: false
    }),
    [fetchOrdersSuccess]: (state, action) => ({
      _isFetching: false,
      _isSaving: false,
      _error: false,
      ...action.result.val()
    }),
    [fetchOrderSuccess]: (state, action) => ({
      ...state,
      _isFetching: false,
      [action.result.val().id]: { ...action.result.val() }
    }),
    [syncOrderSuccess]: (state, action) => ({
      ...state,
      _isFetching: false,
      [action.payload.id]: { ...action.payload }
    }),
    [syncOrdersSuccess]: (state, action) => ({
      _isFetching: false,
      _isSaving: false,
      _error: false,
      ...action.payload
    })
  },
  INITIAL_STATE
)
