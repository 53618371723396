import { useGlobalShortcut } from './useGlobalShortcut';

import { useState } from 'react';
import { Toggle } from './Toggle';
import { useGlobalDevelopmentSettings } from './useGlobalDevelopmentSettings';

export function DevelopmentPanel() {
  const [open, setOpen] = useState(false);

  useGlobalShortcut(() => {
    setOpen((prev) => !prev);
  });

  const [devSettings, setDevSettings] = useGlobalDevelopmentSettings();

  return (
    <div
      style={{
        display: open ? 'block' : 'none',
        position: 'fixed',
        backgroundColor: '#333',
        color: '#eee',
        right: '10px',
        bottom: '10px',
        zIndex: '999999',
        padding: '8px',
      }}
    >
      <Toggle
        label="Use ShapeDiver V3"
        checked={devSettings.useShapediverV3}
        onChange={(v) => setDevSettings({ useShapediverV3: v })}
      />

      <Toggle
        label="Show debugging messages"
        checked={devSettings.showDebuggingMessages}
        onChange={(v) => setDevSettings({ showDebuggingMessages: v })}
      />
    </div>
  );
}
