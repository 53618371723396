import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import promiseMiddleware from '../middleware/promiseMiddleware';
import rootReducer from './reducers';

const win = window as any;

// eslint-disable-next-line
const composeEnhancers =
  (process.env.NODE_ENV !== 'production' && win.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export function configureStore() {
  const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk, promiseMiddleware))(
    createStore
  );

  const store = createStoreWithMiddleware(rootReducer);

  /**
   * Disable HMR for now during the TS conversion. This
   * might need some updating to work with TS and Vite.
   */
  // if (module.hot) {
  //   // Enable Webpack hot module replacement for reducers
  //   module.hot.accept('./reducers.js', () => {
  //     const nextRootReducer = require('./reducers.js')
  //     store.replaceReducer(nextRootReducer)
  //   })
  // }

  return store;
}
