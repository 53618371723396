import { handleActions } from 'redux-actions'
import { normalize, schema } from 'normalizr'
import {
  fetchUsersRequest,
  fetchUsersSuccess,
  fetchUsersFailure
} from './actions'

const userSchema = new schema.Entity('users', {}, { idAttribute: 'uid' })
const usersSchema = [userSchema]

const INITIAL_STATE = {
  _isFetching: false,
  _isSaving: false,
  _error: false
}

export const users = handleActions({
  [fetchUsersRequest]: (state, action) => ({
    ...state,
    _isFetching: true,
    _isSaving: true
  }),
  [fetchUsersSuccess]: (state, action) => {
    const normalizedUsers = normalize(action.result.val(), usersSchema)
    return {
      ...state,
      _isSaving: false,
      _isFetching: false,
      entities: normalizedUsers.entities.users,
      result: normalizedUsers.result
      // ...action.result.val()
    }
  },
  [fetchUsersFailure]: (state, action) => ({
    _isFetching: false,
    _isSaving: false,
    _error: action.payload
  })
}, INITIAL_STATE)
