import { useEffect } from 'react';
import AccordionItem from '../AccordionItem';
import { connect } from 'react-redux';
import { getCommonOptions } from '../../store/Common/actions';

interface StateProps {
  requestBuildFaqs: Array<{ title: string; body: string }>;
}

interface DispatchProps {
  getCommonOptions: () => void;
}

type Props = StateProps & DispatchProps;

const Faq = ({ requestBuildFaqs, getCommonOptions }: Props) => {
  useEffect(() => {
    getCommonOptions();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // (not sure if getCommonOptions is stable)

  return (
    <>
      <h3 className="f4 geo-regular mb0 mt4 mh3">Questions and Answers</h3>
      <ul className="ph3 center list pl0 bg-white flex flex-wrap">
        {requestBuildFaqs &&
          requestBuildFaqs.map((item, index) => {
            return (
              <li className=" w-100 pv3 bt b--light-gray" key={index}>
                <AccordionItem heading={item.title}>
                  <div dangerouslySetInnerHTML={{ __html: item.body }} />
                </AccordionItem>
              </li>
            );
          })}
      </ul>
    </>
  );
};

const mapStateToProps = (state: any): StateProps => ({
  requestBuildFaqs: state.common.requestBuildFaqs,
});

export default connect(mapStateToProps, {
  getCommonOptions,
})(Faq);
