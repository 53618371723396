import React from 'react'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount'
import styled from 'styled-components'
import { lightenDarkenColor } from '../../helpers/colorTransforms'
import { getGlobalOptions } from '../../api/getGlobalOptions'

const Wrapper = styled.a`
  border: 0;
  background-color: #fb5515;
  width: fit-content;
  padding: 0.6em 1.5em;
  &:hover {
    text-decoration: none;
    color: white;
    background-color: ${(props) => lightenDarkenColor('#fb5515', 20)};
  }
  &:focus {
    box-shadow: 0 0 0 3px gold;
    outline: 3px solid transparent;
  }
`

const SectionWrapper = styled.section`
  height: calc(100vh);
  display: flex;
  align-items: center;
`

const ContentContainer = styled.div`
  @media (min-width: 768px) {
    margin-top: -60px;
  }
`

class ThankYou extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dataIsReturned: false,
      data: null,
    }
  }

  componentDidMount() {
    getGlobalOptions().then((data) => this.setState({ dataIsReturned: true, data: data }))
  }

  render() {
    if (!this.state.dataIsReturned) {
      return <p>Loading...</p>
    }
    const { request_confirmed_list } = this.state.data

    return (
      <SectionWrapper className="mw6 ph3 center flex-wrap align-center self-center">
        <ContentContainer>
          <div className="ph3 center">
            <ScrollToTopOnMount />
            <h1 className="f1+ geo-semibold">Request Information Confirmed!</h1>
            <div
              className="f5 geo-regular"
              style={{ marginTop: '2rem', marginBottom: '2rem' }}
              dangerouslySetInnerHTML={{ __html: this?.state?.request_confirmed_intro }}
            />
            {request_confirmed_list &&
              request_confirmed_list.map((node, index) => (
                <div key={index} className="flex align-start mb3 ">
                  <div className="w3 mh3 justify-center mt4">
                    <img src={node.icon} alt="" />
                  </div>
                  <div className="w-100">
                    <h4 className="f5 geo-semibold mb2 mt0 tl">{node.headline}</h4>
                    <p
                      className="f5 geo-regular mt0"
                      dangerouslySetInnerHTML={{ __html: node.text }}
                    ></p>
                  </div>
                </div>
              ))}
            <Wrapper
              className="dib geo-semibold f5 link white pv2 pointer link input-reset w-100 ph1 ph2-ns ttu mt4"
              href="https://www.goldfishboat.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Go back to Goldfishboat.com
            </Wrapper>
          </div>
        </ContentContainer>
      </SectionWrapper>
    )
  }
}

export default ThankYou
