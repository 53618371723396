import React from 'react'

const Label = ({ children }) => (
  <div className="geo-semibold white mb0 tc lh-title p-1">
    {children === 'Drivetrain' && 'Choose your '}
    {children}
  </div>
)

export default Label
