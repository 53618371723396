import PropTypes from 'prop-types'
import { Field } from 'redux-form'

import Select from '../Select/Select'
import Currency from '../../components/Currency/Currency'

const ColorLineItem = ({ handleChange, name, options, label, currentSelectedOption }) => {
  const selectedOption = options.find(
    (item) => item.color.term_id === Number(currentSelectedOption)
  )

  return (
    <div className="w-100">
      <div className="flex items-center justify-between">
        <Field
          name={name}
          label={label}
          placeholder="Select a color option"
          component={Select}
          type="select"
          options={options}
          classes="w-75"
          validate={[]}
          autocomplete="color"
          handleChange={handleChange}
        />
        <Currency prices={selectedOption?.prices} />
      </div>
      <hr className="mv2" />
    </div>
  )
}

ColorLineItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array,
  currentSelectedOption: PropTypes.string,
}

export default ColorLineItem
