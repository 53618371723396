const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="10" fill="#82C8D1"></circle>
    <path
      fill="#fff"
      stroke="#fff"
      strokeWidth="0.25"
      d="M6.72 13.967l.087.088.089-.088 3.175-3.175 3.175 3.175.089.088.088-.088.544-.544.088-.088-.088-.089-3.175-3.175 3.175-3.175.088-.089-.088-.088-.544-.544-.088-.088-.089.088-3.175 3.175-3.175-3.175-.088-.088-.089.088-.544.544-.088.088.088.089L9.35 10.07l-3.175 3.175-.088.089.088.088.544.544z"
    ></path>
  </svg>
);

export default CloseIcon;
