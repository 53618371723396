import { getCurrency, getCurrencyKey } from './getCurrency';

import type { TopOption } from '../api/schemas/tops';
import type { DrivetrainOption } from '../api/schemas/drivetrains';
import type { SeatOption } from '../api/schemas/seats';
import type { ColorAreaOption } from '../api/schemas/colorareas';
import type { EquipmentOption } from '../api/schemas/equipments';
import type { AddonOption } from '../api/schemas/addons';
import type { NormalizedResult } from '../store/Options/normalize';
import { BuildType } from '../api/schemas/shared';
import { log } from './log';

interface PricedObject {
  id: number;
  acf?: null | {
    display_name?: null | string;
  };
  prices?: null | {
    [currencyKey: string]: string | number | null;
  };
}

function getEntityCost(
  buildId: string,
  collection: NormalizedResult<PricedObject>,
  itemNumber: number,
  currencyKey: string,
  type: string
) {
  if (!collection.entities[itemNumber]) {
    log('Missing entity definition for item used in build', { buildId, type, itemNumber });
    return 0;
  }

  const definition = collection.entities[itemNumber];

  if (!definition.prices?.[currencyKey]) {
    log('Missing prices definition for item used in build', {
      buildId,
      type,
      itemNumber,
      currencyKey,
      displayName: definition.acf?.display_name ?? 'unknown',
    });
    return 0;
  }

  return Number(definition.prices?.[currencyKey]);
}

export function getCurrentTotalCost(
  drivetrains: NormalizedResult<DrivetrainOption>,
  tops: NormalizedResult<TopOption>,
  seats: NormalizedResult<SeatOption>,
  colorareas: NormalizedResult<ColorAreaOption>,
  equipments: NormalizedResult<EquipmentOption>,
  build: BuildType,
  userCurrency: string,
  addons: NormalizedResult<AddonOption>
): number {
  // Go over all the parts and options for the build, and accumulate the cost here.
  let totalCost = 0;
  let errors: string[] = [];

  // Get the object key for the current currency. E.g. 'price_nok'
  const currencyKey = getCurrencyKey(userCurrency);

  if (!currencyKey) {
    throw new Error(`Invalid currency (${userCurrency})`);
  }

  /**
   *
   *
   * Drivetrains
   *
   *
   */
  if (drivetrains && build.drivetrain) {
    totalCost += getEntityCost(build.id, drivetrains, build.drivetrain, currencyKey, 'drivetrain');
  }

  /**
   *
   *
   * Tops
   *
   *
   */
  if (tops && build.top) {
    totalCost += getEntityCost(build.id, tops, build.top, currencyKey, 'top');
  }

  /**
   *
   *
   * Seat
   *
   *
   */
  if (seats && build.seat) {
    totalCost += getEntityCost(build.id, seats, build.seat, currencyKey, 'seat');
  }

  /**
   *
   *
   * Colors and color areas
   *
   *
   */
  if (colorareas && build.colors) {
    Object.keys(build.colors).forEach((colorKey) => {
      /**
       * Ensure the colorarea is defined
       */
      if (!colorareas.entities[colorKey]) {
        log('Missing entity definition for item used in build', {
          buildId: build.id,
          type: 'color',
          colorKey,
        });
        return;
      }

      // Get the available colors in this colorarea
      const availableColors = colorareas.entities[colorKey]?.acf.colors ?? [];

      // Get the selected color termId for this colorarea
      const termId = Number(build.colors[colorKey]);

      // And get the selected color definition from the available colors
      const selectedColor = availableColors.filter((c) => c.color.term_id === termId);

      if (selectedColor.length === 0) {
        log('Missing color definition for item used in build', {
          buildId: build.id,
          type: 'color',
          colorKey,
          termId,
          currencyKey,
        });
        return;
      } else if (selectedColor.length > 1) {
        log('Multiple color definitions for item used in build', {
          buildId: build.id,
          type: 'color',
          colorKey,
          termId,
          currencyKey,
        });
      }

      selectedColor.forEach((item) => {
        totalCost += Number(item.prices?.[currencyKey]);
      });
    });
  }

  /**
   *
   *
   * Equipment
   *
   *
   */
  if (equipments && build.equipmentLineItems) {
    build.equipmentLineItems?.forEach((equipment) => {
      if (equipment?.equipment) {
        totalCost += getEntityCost(
          build.id,
          equipments,
          equipment.equipment,
          currencyKey,
          'equipment'
        );
      }
    });
  }

  /**
   *
   *
   * Addons
   *
   *
   */
  if (addons && build.addonLineItems) {
    build.addonLineItems?.forEach((addon) => {
      if (addon?.addon) {
        totalCost += getEntityCost(build.id, addons, addon.addon, currencyKey, 'addon');
      }
    });
  }

  return totalCost;
}
