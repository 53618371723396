import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Loading from '../Loading/Loading';
import OptionsIcon from './OptionsIcon';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import ShareModal from './ShareModal';
import { createViewport, createSession, IViewportApi, ISessionApi } from '@shapediver/viewer';
import { useTrackChanges } from './useTrackChanges';
import * as Sentry from '@sentry/browser';

const SdvWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > #sd-viewer {
    position: relative;
    width: 100%;
    height: 100%;
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const OptionsButton = styled.button`
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 100%;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border: none;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const OptionsModal = styled(motion.div)`
  overflow: hidden;
  background: #fff;
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
`;

const getHandheldPosition = (cameraObject: CameraPlacement): CameraCoordinates => {
  const Cp = cameraObject.position;
  const Ct = cameraObject.target;

  // console.log(Cp, Ct)
  let newX = (Cp[0] - Ct[0]) * 1.8 + Ct[0];
  let newY = (Cp[1] - Ct[1]) * 1.8 + Ct[1];
  let newZ = (Cp[2] - Ct[2]) * 1.8 + Ct[2];
  if (Math.abs(newX) < 2700) {
    newX = newX < 0 ? -2700 : 2700;
  }
  if (Math.abs(newY) < 2700) {
    newY = newY < 0 ? -2700 : 2700;
  }
  if (Math.abs(newZ) < 2700) {
    newZ = newZ < 0 ? -2700 : 2700;
  }

  return [newX, newY, newZ];
};

type CameraCoordinates = [x: number, y: number, z: number];

interface CameraPlacement {
  position: CameraCoordinates;
  target: CameraCoordinates;
}

export interface CameraPlacementsCollection {
  main: CameraPlacement;
  seats: CameraPlacement;
  tops: CameraPlacement;
  drivetrains: CameraPlacement;
  [key: string | number]: CameraPlacement;
}

interface ViewerProps {
  addons?: any;
  aluminium?: string;
  cameraPositions?: CameraPlacementsCollection;
  customCameraPosition?: any;
  deck?: string;
  drivetrains?: string;
  equipments?: any;
  flooring?: string;
  hull?: string;
  motor?: string;
  parameterMap?: {
    [key: string]:
      | undefined
      | {
          parameterId?: string | undefined;
          [key: string]: string | undefined;
        };
  };
  visibleScroller: any;
  seats: any;
  tops: any;
  ticket: any;
}

export function Viewer(props: ViewerProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const viewport = useRef<IViewportApi | null>(null);
  const session = useRef<ISessionApi | null>(null);

  const canvas = document.getElementById('canvas') as HTMLCanvasElement;

  /**
   *
   *
   * Extract the ShapeDiver parameters from the props.
   * The parameters object should contain the parameters as they
   * should be currently set in the ShapeDiver model.
   *
   * Note that these parameters have not yet been verified
   * to exist in the ShapeDiver model.
   *
   *
   */
  const parameters = useMemo(() => {
    const { parameterMap, equipments, addons, ...rest } = props;

    const params: Record<string, string> = {};

    if (parameterMap) {
      Object.keys(parameterMap).forEach((key) => {
        const parameterName: string = (rest as any)[key];
        const parameter = parameterMap[key];
        const parameterMapId = parameter?.parameterId;

        if (!key) {
          console.warn('Parameter key is not defined for parameter:', key);
          Sentry.captureMessage(
            `Error: Viewer: Parameter key is not defined for parameter: ${key}`,
            {
              tags: {
                parameter: key,
                parameterName,
                parameterMapId,
              },
            }
          );
          return;
        }

        if (!parameterMapId) {
          console.warn('Parameter map ID is not defined for parameter:', key, parameterName);
          Sentry.captureMessage(
            `Error: Viewer: Parameter map ID is not defined for parameter: ${key}`,
            {
              tags: {
                parameter: key,
                parameterName,
                parameterMapId,
              },
            }
          );
          return;
        }

        if (!parameterName) {
          console.warn('Parameter does not exist in parameter map:', key);
          Sentry.captureMessage(
            `Error: Viewer: Parameter does not exist in parameter map: ${key}`,
            {
              tags: {
                parameter: key,
                parameterName,
                parameterMapId,
              },
            }
          );
          return;
        }

        let parameterMapValue: string | number | undefined = parameter[parameterName];

        if (typeof parameterMapValue === 'undefined') {
          console.warn(
            'Parameter value can not be associated with model parameters. Setting to default (0). Parameter is:',
            parameterName,
            parameterMapValue
          );
          parameterMapValue = 0;
        }

        params[parameterMapId] = parameterMapValue;
      });
    }

    if (equipments) {
      Object.keys(equipments).forEach((key) => {
        params[key] = equipments[key];
      });
    }

    if (addons) {
      Object.keys(addons).forEach((key) => {
        params[key] = addons[key];
      });
    }

    return params;
  }, [props]);

  /**
   *
   *
   * Keep track of changes in the parameters object to keep the
   * ShapeDiver viewer up to date with the parameter changes.
   *
   *
   */
  useTrackChanges(parameters, {
    onPropertyChanged: (key, value, previousValue) => {
      // console.log('Changed parameter', key, 'from', previousValue, 'to', value);
      if (session.current) {
        session.current.parameters[key].value = value;
      }
    },
    onPropertyRemoved: (key, previousValue) => {
      // console.log('Removed parameter', key, 'with last value', previousValue);
      if (session.current) {
        session.current.parameters[key].resetToDefaultValue();
      }
    },
    onAfterChanges: () => {
      // console.log('Current parameters', parameters);
      session.current?.customize();
    },
  });

  /**
   *
   *
   * Initialize Shapediver
   *
   *
   */
  useEffect(() => {
    async function asyncEffect() {
      viewport.current = await createViewport({
        canvas: canvasRef.current ?? undefined,
        id: 'myViewport',
      });

      session.current = await createSession({
        ticket: props.ticket,
        modelViewUrl: 'https://sdeuc1.eu-central-1.shapediver.com',
        id: 'mySession',
        initialParameterValues: parameters,
      });

      // (window as any).session = session.current;
      // (window as any).viewport = viewport.current;

      setLoading(false);

      console.log('Initialized ShapeDiver');
    }
    asyncEffect();
  }, [props.ticket]);

  /**
   *
   *
   * Extract the camera position code from the below section
   *
   *
   */
  useEffect(() => {
    if (props.customCameraPosition) {
      let cameraObject = props.customCameraPosition;
      if (window.innerWidth < 960) {
        cameraObject.position = getHandheldPosition(cameraObject);
      }
      viewport.current?.camera?.set(cameraObject.position, cameraObject.target);
    } else if (props.visibleScroller) {
      let cameraObject: Partial<CameraPlacement> =
        props.cameraPositions?.[props.visibleScroller] ?? props.cameraPositions?.main ?? {};
      if (window.innerWidth < 960) {
        cameraObject.position = getHandheldPosition(cameraObject);
      }
      if (cameraObject.position && cameraObject.target) {
        viewport.current?.camera?.set(cameraObject.position, cameraObject.target);
      }
    } else {
      let cameraObject = props.cameraPositions?.main ?? {
        position: [-4971.33895337932, 8097.514198267563, 7519.7680611228825],
        target: [104.61290652018073, 3886.440285755593, 3.015489744271154],
      };

      if (window.innerWidth < 960) {
        cameraObject.position = getHandheldPosition(cameraObject);
      }
      viewport.current?.camera?.set(cameraObject.position, cameraObject.target);
    }
  }, [props.cameraPositions, props.customCameraPosition, props.visibleScroller]);

  /**
   *
   *
   * Download an image of the canvas
   *
   *
   */
  const downloadImage = useCallback(() => {
    if (!canvasRef.current) {
      return;
    }
    const link = document.createElement('a');
    link.download = 'boat.png';
    link.href = canvasRef.current.toDataURL();
    link.click();
  }, []);

  return (
    <>
      <SdvWrapper>
        {loading ? (
          <LoadingWrapper>
            <Loading />
          </LoadingWrapper>
        ) : null}

        <canvas ref={canvasRef}></canvas>

        <OptionsButton
          className="ma3"
          onClick={() => {
            setModalOpen(!modalOpen);
          }}
        >
          <OptionsIcon />
        </OptionsButton>

        <AnimatePresence>
          {modalOpen && (
            <OptionsModal
              className="mr3"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
            >
              <button
                className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
                disabled={!session.current}
                onClick={() => {
                  canvasRef.current?.requestFullscreen();
                }}
              >
                Full view
              </button>

              <div className="bb b--light-gray db"></div>

              <button
                className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
                onClick={() => {
                  console.log('Camera position');
                  // Convert explicitly from a Float32Array to
                  // maintain compatibility with previous viewer
                  console.log({
                    position: {
                      x: viewport.current?.camera?.position?.[0],
                      y: viewport.current?.camera?.position?.[1],
                      z: viewport.current?.camera?.position?.[2],
                    },
                    target: {
                      x: viewport.current?.camera?.target?.[0],
                      y: viewport.current?.camera?.target?.[1],
                      z: viewport.current?.camera?.target?.[2],
                    },
                  });
                }}
              >
                Get camera
              </button>

              <div className="bb b--light-gray db"></div>

              <button
                className="bg-white w-100 lh-solid pv3 db bw0 pointer hover-bg-near-white"
                onClick={downloadImage}
              >
                Download Image
              </button>

              <div className="bb b--light-gray db"></div>

              <ShareModal></ShareModal>
            </OptionsModal>
          )}
        </AnimatePresence>
      </SdvWrapper>
    </>
  );
}
