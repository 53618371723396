import { handleActions, combineActions } from 'redux-actions';
import {
  fetchAllOptionsRequest,
  fetchAllOptionsSuccess,
  fetchAllOptionsFailure,
  fetchOptionRequest,
  fetchOptionSuccess,
  fetchOptionFailure,
  fetchBoatDefinitionsRequest,
  fetchBoatDefinitionsSuccess,
  fetchBoatDefinitionsFailure,
} from './actions';
import { BoatDefinition } from '../../api/getBoatDefinitions';
import { GetAllOptionsForModelResult } from '../../api/getAllOptionsForModel';
import { NormalizedResult, normalize } from './normalize';
import { AddonOption } from '../../api/schemas/addons';
import { BoatOption } from '../../api/schemas/boats';
import { ColorAreaOption } from '../../api/schemas/colorareas';
import { ColorOption } from '../../api/schemas/color';
import { DrivetrainOption } from '../../api/schemas/drivetrains';
import { EquipmentOption } from '../../api/schemas/equipments';
import { SeatOption } from '../../api/schemas/seats';
import { TopOption } from '../../api/schemas/tops';

export type OptionsState = {
  _isFetching: boolean;
  _error: any;
  addons?: NormalizedResult<AddonOption>;
  boats?: NormalizedResult<BoatOption>;
  colors?: NormalizedResult<ColorOption>;
  colorareas?: NormalizedResult<ColorAreaOption>;
  drivetrains?: NormalizedResult<DrivetrainOption>;
  equipments?: NormalizedResult<EquipmentOption>;
  seats?: NormalizedResult<SeatOption>;
  tops?: NormalizedResult<TopOption>;
  boatDefinitionsById?: { [id: number]: BoatDefinition };
};

const INITIAL_STATE: OptionsState = {
  _isFetching: true,
  _error: null,
};

export const options = handleActions(
  {
    [combineActions(
      fetchAllOptionsRequest,
      fetchOptionRequest,
      fetchBoatDefinitionsRequest
    ) as any]: (state: OptionsState): OptionsState => {
      return { ...state, _isFetching: true };
    },

    [combineActions(
      fetchAllOptionsFailure,
      fetchOptionFailure,
      fetchBoatDefinitionsFailure
    ) as any]: (state: OptionsState, action: any) => ({
      ...state,
      _isFetching: false,
      _error: action.error.response ? action.error.data.message : action.error,
    }),

    [fetchAllOptionsSuccess as any]: (state: OptionsState, action: any): OptionsState => {
      const resultObject = action.result as GetAllOptionsForModelResult;

      // This following statement would probably have used "normalizr" in the previous
      // (pre-2020) style of development. We've simplified the structure, and a simple
      // reducer should be sufficient.
      const normalizedResults: Partial<OptionsState> = {
        addons: normalize(resultObject.addons),
        boats: normalize(resultObject.boats),
        colors: normalize(resultObject.color),
        colorareas: normalize(resultObject.colorareas),
        drivetrains: normalize(resultObject.drivetrains),
        equipments: normalize(resultObject.equipments),
        seats: normalize(resultObject.seats),
        tops: normalize(resultObject.tops),
      };

      return {
        ...state,
        ...normalizedResults,
        _isFetching: false,
      };
    },

    [fetchOptionSuccess as any]: (state: OptionsState, action: any): OptionsState => {
      const normalizedOption = normalize(action.result);
      return {
        ...state,
        [action.result[0].type]: normalizedOption,
        _isFetching: false,
      };
    },

    [fetchBoatDefinitionsSuccess as any]: (state: OptionsState, action: any): OptionsState => {
      const results = action.result as Array<{ id: number; title: string | null }>;

      // This following statement would probably have used "normalizr" in the previous
      // (pre-2020) style of development. We've simplified the structure, and a simple
      // reducer should be sufficient.
      const boatDefinitionsById = results.reduce((acc, boatDefinition) => {
        acc[boatDefinition.id] = boatDefinition;
        return acc;
      }, {} as { [id: number]: BoatDefinition });

      return {
        ...state,
        _isFetching: false,
        boatDefinitionsById,
      };
    },
  },
  INITIAL_STATE
);
