import { Motion, spring } from 'react-motion'
import { Component } from 'react'

import BoatImagePoc from '../../components/BoatImage/BoatImagePoc'
import CheckCircleSVG from '../../components/Icons/CheckCircleSVG'
import ColorSwatch from '../../components/ColorSwatch/ColorSwatch'
import Currency from '../../components/Currency/Currency'
import DrivetrainCard from '../../components/DrivetrainCard/DrivetrainCard'
import Helmet from 'react-helmet'
import ImageZoom from 'react-medium-image-zoom'
import Label from '../../components/Label/Label'
import Loading from '../../components/Loading/Loading'
import P from '../../components/P/P'
import ProgressiveImage from 'react-progressive-image'
import ScrollerItem from '../../components/ScrollerItem/ScrollerItem'
import ScrollerVertical from '../../components/ScrollerVertical/ScrollerVertical'
import { Tag } from '../../components/Tag'
import areEquipmentRequirementsMet from '../../helpers/areEquipmentRequirementsMet'
import presets from '../../helpers/motionPresets'
import styled from 'styled-components'
import { BackArrow } from '../../components/Icons/BackArrow'
import { hideDiscontinuedOptions } from '../Builder/hideDiscontinuedOptions'

// Components

const Wrapper = styled.section`
  height: ${window.innerHeight - 52 - 172}px;
  @media (min-width: 64em) {
    height: ${window.innerHeight - 52 - 172}px;
  }
`

const Price = styled.span`
  color: ${(props) => (props.selected ? '#a6dde8' : '#869396')};
`

const ColorPrice = styled.div`
  color: #869396;
  padding-bottom: 0.15rem;
`

const Boxy = styled.div`
  width: 250px;
`

const Box = styled.div`
  background: ${(props) =>
    props.selected
      ? 'linear-gradient(to top left, rgb(127,175,185) , rgba(13, 40, 46, 1))'
      : 'linear-gradient(90deg, rgba(37, 61, 67, 1) , rgba(13, 40, 46, 1))'};
  height: 100%;
  border-left: none;
  flex: 0 0 40%;
  border-top: none;
  border-bottom: none;
  @media (min-width: 30em) {
    flex: 0 0 22.5%;
  }
  @media (min-width: 64em) {
    height: 142px;
  }
  outline: none;
  text-align: left;
`

const Menu = styled.div`
  @media (min-width: 30em) {
    min-width: 200px;
  }
  min-width: 90px;
  height: 35px;
  &:hover {
    opacity: 1;
    color: #869396;
    text-decoration: none;

    .back-image {
      opacity: 0.55;
    }
  }
`

const DriveScroll = styled.div`
  -webkit-overflow-scrolling: touch;
  width: 100%;
  z-index: 10;
  height: 250px;
  margin-top: 6rem;
  background: #0d282e;
`

const EquipmentItem = (props) => {
  const {
    equipment,
    children,
    onClick,
    slug,
    value,
    equipmenttype,
    selected,
    type = 'equipmentLineItems',
    superLightCarbonSelected = false,
  } = props

  const showSuperLightTag =
    superLightCarbonSelected && equipment?.acf?.superlight_carbon_compatible === false

  return (
    <div
      // className="gradient-scroll h-100 pointer "
      // style={{ border: 'none', background: 'transparent', width: '280px', height: '100%' }}
      onClick={onClick}
      data-name={slug}
      data-type={type}
      data-value={value}
      data-equipmenttype={equipmenttype}
      style={{ height: '100%' }}
      // className={equipment?.acf?.superlight_carbon_compatible == false && 'pointer-events-none'}
    >
      <Boxy className="flex justify-between pt3 ph3 flex-column" style={{ height: '100%' }}>
        <div>
          {equipment.acf.image && (
            <div className="relative bg-center dib w-100 cover" style={{ zIndex: 2 }}>
              <div className="absolute">
                {showSuperLightTag && <Tag type="orange">Not recommended for SuperLight</Tag>}

                {!showSuperLightTag && equipment.acf.label && (
                  <Tag hex="#fff" opacity="0.7" classes="br--right pointer-events-none">
                    {' '}
                    {equipment.acf.label}
                  </Tag>
                )}

                {!showSuperLightTag && equipment.acf.speed_penalty && (
                  <Tag hex="#a6dde8" classes="br--right pointer-events-none">
                    {' '}
                    {equipment.acf.speed_penalty}
                  </Tag>
                )}
              </div>

              {/* showSuperLightTag && (
                <div style={{ right: '10px' }} className="absolute top-0">
                  <img
                    className="relative pointer-events-none dib h3 mt3 mt0-ns"
                    src={`/style-thumbnails/sl-not-compatible-icon.png`}
                    alt="Not SuperLight Carbon Compatible"
                  />
                </div>
              ) */}

              <ProgressiveImage
                src={`https://img.gs/mnhprjkmmk/258x105,3x/${equipment.acf.image.url}`}
                placeholder={`https://img.gs/mnhprjkmmk/258x105,quality=low/${equipment.acf.image.url}`}
              >
                {(src) => (
                  <ImageZoom
                    image={{
                      src: `${src}`,
                      alt: `${equipment.acf.display_name}`,
                      className: `mb0 nt2 ${props.selected ? 'border-bottom' : ''}`,
                    }}
                    zoomImage={{
                      src: `https://img.gs/mnhprjkmmk/1200/${equipment.acf.image.url}`,
                      alt: `${equipment.acf.display_name}`,
                      className: 'mb0 nt2',
                    }}
                  />
                )}
              </ProgressiveImage>
            </div>
          )}

          {/* {slug && (
            <button
              className="gradient-scroll absolute top-0 left-0 w-100 h-100 pointer "
              style={{ border: 'none', background: 'transparent' }}
              onClick={onClick}
              data-name={slug}
              data-type="equipmentLineItems"
              data-value={value}
              data-equipmenttype={equipmenttype}
            ></button>
          )} */}

          {!equipment.acf.image && (
            <div
              className="relative bg-center pointer-events-none dib w-100 bg-light-gray cover"
              style={{
                height: '105px',
              }}
            >
              {equipment.acf.label && (
                <Tag hex="#FFE19D" classes="br--right">
                  {' '}
                  {equipment.acf.label}
                </Tag>
              )}
              {equipment.acf.speed_penalty && (
                <Tag hex="#C0DEC0" classes="br--right">
                  {' '}
                  {equipment.acf.speed_penalty}
                </Tag>
              )}
            </div>
          )}

          {/* here  */}
          <p className="pointer-events-none geo-semibold white mv2 p-2 lh-solid">
            {equipment.acf.display_name}
            <Price selected={selected}>
              <Currency
                prices={equipment.prices}
                className="relative pointer-events-none geo-semibold ml1"
              />
            </Price>
          </p>
          {equipment.acf.text && <P>{equipment.acf.text.slice(0, 140)}</P>}
          {!equipment.acf.text && equipment.acf.description && (
            <P className="white">{equipment.acf.description.slice(0, 140)}</P>
          )}
        </div>
        {children}
      </Boxy>
    </div>
  )
}

class Style extends Component {
  constructor(props) {
    super(props)
    // const view = props.openView === 'drivetrains' ? 'drivetrains' : 'main'
    const view = 'main'

    this.state = { visibleScroller: view, lastChosenEquipmentId: false, lastChosenAddonId: false }
    this.setSelection = this.setSelection.bind(this)
  }

  setVisibleScroller(e) {
    let value = null

    if (e.target.dataset !== undefined) {
      value = e.target.dataset.value
    } else {
      value = e.target.getAttribute('data-value')
    }

    this.setState({
      visibleScroller: `${value}`,
      lastChosenEquipmentId: false,
      lastChosenAddonId: false,
    })
  }

  setSelection(e) {
    this.props.handleSelect(e)
    let fieldValue
    let subName
    if (e.target.dataset !== undefined) {
      fieldValue = e.target.value || e.target.dataset.value
      subName = e.target.dataset.type // To set a child containing name and value
    } else {
      fieldValue = e.target.value || e.target.getAttribute('data-value')
      subName = e.target.getAttribute('data-type') // To set a child containing name and value
    }
    if (subName === 'equipmentLineItems') {
      // Get equipment camerapos and target and update state
      this.setState({ lastChosenEquipmentId: fieldValue })
    } else {
      // Set custom camera target and position to false
      this.setState({ lastChosenEquipmentId: false })
    }
    if (subName === 'addonLinesItems') {
      // Get equipment camerapos and target and update state
      this.setState({ lastChosenAddonId: fieldValue })
    } else {
      // Set custom camera target and position to false
      this.setState({ lastChosenAddonId: false })
    }
  }

  render() {
    const { build, openView, touchedSelections } = this.props
    const options = hideDiscontinuedOptions(this.props.options)

    // console.log('build', build)
    // console.log(options.addons)
    const { lastChosenEquipmentId, lastChosenAddonId } = this.state

    const setSelection = this.setSelection

    const boat = options.boats ? options.boats.entities[options.boats.result[0]] : false
    // const showPrice = boat && boat.acf.boat_class === 'consumer'
    const showPrice = true

    /**
     * Find the addon representing SuperLight Carbon. The slug for this can differ between the boats,
     * so we use the hardcoded addon type ID 197 instead. There should always only be a single
     * addon of this type, and this addon should only have this single type.
     *
     * Alternatively, we could also find the addon type with slug `superlight`, but it wouldn't
     * make a big difference; we still rely on something explicit and permanent in the WP DB.
     */
    const superLightAddonTypeId = 197

    // Find the addon ID representing SuperLight Carbon
    const superLightCarbonId = options.addons?.result?.find(
      (a) => options.addons?.entities[a]?.addonType?.[0] === superLightAddonTypeId
    )

    // And check if that particular addon is enabled in the build
    const superLightCarbonSelected = build?.addonLineItems?.find(
      (item) => Number(item.addon) === superLightCarbonId
    )

    // Get the equipment categories we want
    const equipments = options.equipments
    let equipmentTypes = {}
    if (equipments && equipments.result) {
      equipments.result.map((key) => {
        const item = equipments.entities[key]
        const term = item._embedded['wp:term'][0].find((i) => i.taxonomy === 'equipmentType')

        if (term.slug !== 'clothing') {
          if (equipmentTypes[term.slug]) {
            equipmentTypes[term.slug].items = [...equipmentTypes[term.slug].items, item]
            return equipmentTypes
          } else {
            //
            equipmentTypes[term.slug] = term
            equipmentTypes[term.slug].items = [item]
            return equipmentTypes
          }
        }
        return equipmentTypes
      })
    }

    // Get the addon categories we want
    const addons = options.addons
    let addonTypes = {}
    if (addons && addons.result) {
      addons.result.map((key) => {
        const item = addons.entities[key]
        // Note; there might be addons without an addonType at all
        // We will ignore those.
        if (!item._embedded['wp:term'] || !item._embedded['wp:term'][0]) {
          return addonTypes
        }
        const term = item._embedded['wp:term'][0].find((i) => i.taxonomy === 'addonType')
        if (!term) {
          return addonTypes
        }
        if (addonTypes[term.slug]) {
          addonTypes[term.slug].items = [...addonTypes[term.slug].items, item]
          return addonTypes
        } else {
          //
          addonTypes[term.slug] = term
          addonTypes[term.slug].items = [item]
          return addonTypes
        }
      })
    }

    // console.log(addonTypes)

    if (!build) {
      return (
        <Wrapper className="relative overflow-hidden">
          <Helmet title={`Style and configure`} />
          <div className="fixed bottom-0 w-100">
            <ScrollerVertical>
              <ScrollerItem data-value="seats" onClick={this.setVisibleScroller.bind(this)}>
                <Loading />
              </ScrollerItem>
              <ScrollerItem data-value="seats" onClick={this.setVisibleScroller.bind(this)}>
                <Loading />
              </ScrollerItem>
              <ScrollerItem data-value="seats" onClick={this.setVisibleScroller.bind(this)}>
                <Loading />
              </ScrollerItem>
              <ScrollerItem data-value="seats" onClick={this.setVisibleScroller.bind(this)}>
                <Loading />
              </ScrollerItem>
              <ScrollerItem data-value="seats" onClick={this.setVisibleScroller.bind(this)}>
                <Loading />
              </ScrollerItem>
              <ScrollerItem data-value="seats" onClick={this.setVisibleScroller.bind(this)}>
                <Loading />
              </ScrollerItem>
            </ScrollerVertical>
          </div>
        </Wrapper>
      )
    }
    return (
      <Wrapper className="relative overflow-hidden">
        <Helmet title={`Style and configure`} />
        {build && build.seat && build.top && (
          <BoatImagePoc
            options={options}
            build={build}
            boat={boat}
            lastChosenEquipmentId={lastChosenEquipmentId}
            lastChosenAddonId={lastChosenAddonId}
            visibleScroller={this.state.visibleScroller}
          />
        )}

        {build && openView && (
          <Motion
            style={{
              opacity: spring(this.state.visibleScroller === 'main' ? 1 : 0, presets.opacity),
              y: spring(this.state.visibleScroller === 'main' ? 0 : 50, presets.stiff),
              zIndex: this.state.visibleScroller === 'main' ? 4 : 0,
              pointerEvents: this.state.visibleScroller === `main` ? 1 : 0,
            }}
          >
            {({ opacity, y, zIndex, pointerEvents }) => (
              <div
                className="fixed bottom-0 w-100"
                style={{
                  opacity: `${opacity}`,
                  transform: `translate3d(0, ${y}px, 0)`,
                  zIndex: zIndex,
                  pointerEvents: pointerEvents ? 'auto' : 'none',
                }}
              >
                <ScrollerVertical height="141px">
                  {openView === 'drivetrains' && (
                    <ScrollerItem
                      data-value="drivetrains"
                      flexOption="0 0 100%"
                      onClick={this.setVisibleScroller.bind(this)}
                    >
                      {touchedSelections && touchedSelections.includes('drivetrain') && (
                        <CheckCircleSVG
                          className="absolute bottom-1-ns right-1"
                          style={{ width: '18px', height: '18px' }}
                        />
                      )}
                      <img
                        className="relative pointer-events-none dib h3 mt3 mt0-ns"
                        src={`/style-thumbnails/drivetrain.png`}
                        alt="Drivetrains"
                      />
                      <Label className="geo-semibold">Drivetrain</Label>
                    </ScrollerItem>
                  )}
                  {openView === 'tops-and-seats' && (
                    <>
                      <ScrollerItem
                        data-value="seats"
                        flexOption="0 0 60%"
                        purpleSelect="true"
                        onClick={this.setVisibleScroller.bind(this)}
                        selected={touchedSelections.includes('seat')}
                      >
                        {touchedSelections && touchedSelections.includes('seat') && (
                          <CheckCircleSVG
                            className="absolute bottom-1-ns right-1"
                            style={{ width: '18px', height: '18px' }}
                          />
                        )}
                        <div
                          className="absolute geo-semibold blue-green ph3 pv1 top-1 left-1 p-3"
                          style={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
                        >
                          {options.seats.entities[build.seat] &&
                            options.seats.entities[build.seat].acf.display_name}
                        </div>
                        {options.seats.entities[build.seat].acf.thumbnail && (
                          <img
                            className="relative pointer-events-none dib h3 mt4 mt0-ns"
                            src={`${options.seats.entities[build.seat].acf.thumbnail.url}`}
                            alt="Color"
                          />
                        )}
                        <Label className="white">
                          <span className="fw2">Click to choose</span> Seat
                        </Label>
                      </ScrollerItem>
                      {options.tops.result.length > 1 && (
                        <ScrollerItem
                          data-value="tops"
                          flexOption="0 0 60%"
                          purpleSelect="true"
                          onClick={this.setVisibleScroller.bind(this)}
                          selected={touchedSelections.includes('top')}
                        >
                          {touchedSelections && touchedSelections.indexOf('top') !== -1 && (
                            <CheckCircleSVG
                              className="absolute bottom-1-ns right-1"
                              style={{ width: '18px', height: '18px' }}
                            />
                          )}
                          <div
                            className="absolute geo-semibold blue-green ph3 pv1 top-1 left-1 p-3"
                            style={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
                          >
                            {options.tops.entities[build.top] &&
                              options.tops.entities[build.top].acf.display_name}
                          </div>

                          {options.tops.entities[build.top].acf.thumbnail && (
                            <img
                              className="relative pointer-events-none dib h3 mt4 mt0-ns"
                              src={`${options.tops.entities[build.top].acf.thumbnail.url}`}
                              alt="Color"
                            />
                          )}
                          <Label className="white">
                            <span className="fw2">Click to choose</span> Top
                          </Label>
                        </ScrollerItem>
                      )}
                      {options?.addons?.result.length >= 1 &&
                        Object.keys(addonTypes).map((key) => (
                          <ScrollerItem
                            flexOption="0 0 60%"
                            key={`${key}`}
                            data-value={key}
                            onClick={this.setVisibleScroller.bind(this)}
                          >
                            {touchedSelections && touchedSelections.includes(key) && (
                              <CheckCircleSVG
                                className="absolute bottom-1-ns right-1"
                                style={{ width: '18px', height: '18px' }}
                              />
                            )}
                            {/* <div
                              className="absolute geo-semibold blue-green ph3 pv1 top-1 left-1 p-3"
                              style={{ backgroundColor: 'rgba(255,255,255, 0.1)' }}
                            >
                              {options.addons?.results?.find(a => a === )}
                            </div> */}
                            {addonTypes[key].acf.thumbnail && (
                              <img
                                className="relative pointer-events-none dib h3 mt2 mt0-ns"
                                src={`${addonTypes[key].acf.thumbnail.url}`}
                                alt="Addon"
                              />
                            )}
                            <Label className="geo-semibold">
                              <span className="fw2">Click to choose </span>
                              {addonTypes[key].name}
                            </Label>
                          </ScrollerItem>
                        ))}
                    </>
                  )}
                  {openView === 'colors' && (
                    <>
                      {build &&
                        options.colorareas.result.map((colorarea, idx) => (
                          <ScrollerItem
                            flexOption="0 0 40%"
                            key={`${colorarea}${idx}`}
                            data-value={colorarea}
                            onClick={this.setVisibleScroller.bind(this)}
                            // selected={touchedSelections.includes(colorarea.toString())}
                          >
                            <ColorSwatch
                              small={false}
                              color={options.colors.entities[Number(build.colors[colorarea])]}
                            />
                            <span></span>
                            {touchedSelections &&
                              touchedSelections.includes(colorarea.toString()) && (
                                <CheckCircleSVG
                                  className="absolute bottom-1-ns right-1"
                                  style={{ width: '18px', height: '18px' }}
                                />
                              )}
                            {options.colorareas.entities[colorarea].acf.thumbnail && (
                              <img
                                className="relative pointer-events-none dib h3 mt3 mt0-ns"
                                src={`${options.colorareas.entities[colorarea].acf.thumbnail.url}`}
                                alt="Color"
                              />
                            )}
                            <Label>{options.colorareas.entities[colorarea].acf.display_name}</Label>
                          </ScrollerItem>
                        ))}
                    </>
                  )}
                  {openView === 'equipment' && (
                    <>
                      {build &&
                        Object.keys(equipmentTypes).map((key) => (
                          <ScrollerItem
                            flexOption="0 0 40%"
                            key={`${key}`}
                            data-value={key}
                            onClick={this.setVisibleScroller.bind(this)}
                          >
                            {touchedSelections && touchedSelections.includes(key) && (
                              <CheckCircleSVG
                                className="absolute bottom-1-ns right-1"
                                style={{ width: '18px', height: '18px' }}
                              />
                            )}
                            {equipmentTypes[key].acf.thumbnail && (
                              <img
                                className="relative pointer-events-none dib h3 mt2 mt0-ns"
                                src={`${equipmentTypes[key].acf.thumbnail.url}`}
                                alt="Color"
                              />
                            )}
                            <Label className="geo-semibold">{equipmentTypes[key].name}</Label>
                          </ScrollerItem>
                        ))}
                    </>
                  )}
                </ScrollerVertical>
              </div>
            )}
          </Motion>
        )}
        <Motion
          style={{
            opacity: spring(this.state.visibleScroller === 'drivetrains' ? 1 : 0, presets.opacity),
            y: spring(this.state.visibleScroller === 'drivetrains' ? 0 : 340, presets.stiff),
            zIndex: this.state.visibleScroller === 'drivetrains' ? 4 : 0,
            pointerEvents: this.state.visibleScroller === `drivetrains` ? 1 : 0,
          }}
        >
          {({ opacity, y, zIndex, pointerEvents }) => (
            <div
              className="fixed bottom-0 w-100"
              style={{
                opacity: `${opacity}`,
                transform: `translate3d(0, ${y}px, 0)`,
                zIndex: zIndex,
                pointerEvents: pointerEvents ? 'auto' : 'none',
                marginTop: 57,
              }}
            >
              <ScrollerVertical height="348px" mBottom="64px" bgColor="transparent">
                {build &&
                  options.drivetrains.result.map((drivetrain, idx) => (
                    <DrivetrainCard
                      key={`${drivetrain}${idx}`}
                      data-name="drivetrain"
                      data-value={drivetrain}
                      onClick={setSelection}
                      id={drivetrain}
                      drivetrain={options.drivetrains.entities[drivetrain]}
                      selected={
                        build && build.drivetrain && drivetrain === Number(build.drivetrain)
                      }
                      showPrice={true}
                    />
                  ))}
                <DriveScroll></DriveScroll>
              </ScrollerVertical>
            </div>
          )}
        </Motion>
        <Motion
          style={{
            opacity: spring(this.state.visibleScroller === 'seats' ? 1 : 0, presets.opacity),
            y: spring(this.state.visibleScroller === 'seats' ? 0 : 65, presets.stiff),
            zIndex: this.state.visibleScroller === 'seats' ? 4 : 0,
            pointerEvents: this.state.visibleScroller === `seats` ? 1 : 0,
          }}
        >
          {({ opacity, y, zIndex, pointerEvents }) => (
            <div
              className="fixed bottom-0 w-100"
              style={{
                opacity: `${opacity}`,
                transform: `translate3d(0, ${y}px, 0)`,
                zIndex: zIndex,
                pointerEvents: pointerEvents ? 'auto' : 'none',
                marginTop: 57,
              }}
            >
              <ScrollerVertical height="280px">
                {build &&
                  options.seats.result.map((seat, idx) => (
                    <ScrollerItem
                      key={`${seat}${idx}`}
                      data-name="seat"
                      data-value={seat}
                      onClick={setSelection}
                      selected={seat === Number(build.seat)}
                      style={{ height: '280px', maxWidth: '290px' }}
                    >
                      <EquipmentItem
                        equipment={options.seats.entities[seat]}
                        selected={seat === Number(build.seat)}
                        data-name="seat"
                        data-value={seat}
                        onClick={setSelection}
                      />
                    </ScrollerItem>
                  ))}
              </ScrollerVertical>
            </div>
          )}
        </Motion>
        <Motion
          style={{
            opacity: spring(this.state.visibleScroller === 'tops' ? 1 : 0, presets.opacity),
            y: spring(this.state.visibleScroller === 'tops' ? 0 : 65, presets.stiff),
            zIndex: this.state.visibleScroller === 'tops' ? 4 : 0,
            pointerEvents: this.state.visibleScroller === `tops` ? 1 : 0,
          }}
        >
          {({ opacity, y, zIndex, pointerEvents }) => (
            <div
              className="fixed bottom-0 w-100 "
              style={{
                opacity: `${opacity}`,
                transform: `translate3d(0, ${y}px, 0)`,
                zIndex: zIndex,
                marginTop: 57,
                pointerEvents: pointerEvents ? 'auto' : 'none',
              }}
            >
              <ScrollerVertical height="280px">
                {build &&
                  options.tops.result.map((top, idx) => (
                    <ScrollerItem
                      key={`${top}${idx}`}
                      data-name="top"
                      data-value={top}
                      onClick={setSelection}
                      selected={top === Number(build.top)}
                      style={{ height: '280px', maxWidth: '290px' }}
                    >
                      {/* <img
                        className="relative pointer-events-none dib h3"
                        src={`/style-thumbnails/${build.boatSlug}/${options.tops.entities[top].acf.display_name}.png`}
                        alt="Top"
                      />
                      <Label>
                        {options.tops.entities[top].acf.display_name}{' '}
                        {showPrice && (
                          <Currency className="relative pointer-events-none o-60">
                            {options.tops.entities[top].acf.price}
                          </Currency>
                        )}
                      </Label> */}
                      <EquipmentItem
                        equipment={options.tops.entities[top]}
                        selected={top === Number(build.top)}
                      >
                        {/* <EquipmentButton
                          data-name="top"
                          data-value={top}
                          onClick={setSelection}
                          selected={top === Number(build.top)}
                          className="flex f5 geo-semibold black lh-title mt2 mb2"
                        >
                          {top === Number(build.top) ? (
                            <SelectedIcon className="mr2" color="#fff" />
                          ) : (
                            <PlusIcon className="mr2" />
                          )}

                          {top === Number(build.top) ? 'Selected' : 'Add'}
                        </EquipmentButton> */}
                      </EquipmentItem>
                    </ScrollerItem>
                  ))}
              </ScrollerVertical>
            </div>
          )}
        </Motion>
        {build &&
          Object.keys(equipmentTypes).map((key) => (
            <Motion
              key={`${key}`}
              style={{
                opacity: spring(this.state.visibleScroller === `${key}` ? 1 : 0, presets.opacity),
                y: spring(this.state.visibleScroller === `${key}` ? 0 : 65, presets.stiff),
                zIndex: this.state.visibleScroller === `${key}` ? 4 : 0,
                pointerEvents: this.state.visibleScroller === `${key}` ? 1 : 0,
              }}
            >
              {({ opacity, y, zIndex, pointerEvents }) => (
                <div
                  className="fixed bottom-0 w-100 "
                  style={{
                    opacity: `${opacity}`,
                    transform: `translate3d(0, ${y}px, 0)`,
                    zIndex: zIndex,
                    pointerEvents: pointerEvents ? 'auto' : 'none',
                    marginTop: 57,
                  }}
                >
                  <ScrollerVertical height="280px" key={`${key}`}>
                    {equipmentTypes[key].items.map((equipment) => {
                      const isSelected =
                        build.equipmentLineItems && equipment
                          ? !!build.equipmentLineItems?.find(
                              (x) => Number(x.equipment) === equipment.id
                            )
                          : false

                      const requirementsMet = areEquipmentRequirementsMet(equipment, build)
                      if (!requirementsMet) {
                        return null
                      }

                      return (
                        <Box
                          selected={isSelected}
                          key={`${equipment.slug}`}
                          style={{ height: '280px', maxWidth: '290px' }}
                          className={`pointer relative pv2 w-100 bb-l db ws-normal h-100 ios-anti-click-blink ${
                            equipment?.acf?.superlight_carbon_compatible === false &&
                            superLightCarbonSelected &&
                            'pointer-events-none cursor-not-allowed'
                          }`}
                        >
                          <EquipmentItem
                            equipment={equipment}
                            selected={isSelected}
                            slug={equipment.slug}
                            type="equipmentLineItems"
                            value={equipment.id}
                            equipmenttype={key}
                            onClick={setSelection}
                            superLightCarbonSelected={superLightCarbonSelected}
                          >
                            {/* <EquipmentButton
                              data-name={equipment.slug}
                              data-type="equipmentLineItems"
                              data-value={equipment.id}
                              data-equipmenttype={key}
                              onClick={setSelection}
                              selected={isSelected}
                              className="flex f5 geo-semibold black lh-title mt2 mb2 "
                            >
                              {isSelected ? (
                                <SelectedIcon className="mr2" color="#fff" />
                              ) : (
                                <PlusIcon className="mr2" />
                              )}

                              {isSelected ? 'Selected' : 'Add'}
                            </EquipmentButton> */}
                          </EquipmentItem>
                        </Box>
                      )
                    })}
                  </ScrollerVertical>
                </div>
              )}
            </Motion>
          ))}

        {build &&
          Object.keys(addonTypes).map((key) => (
            <Motion
              key={`${key}`}
              style={{
                opacity: spring(this.state.visibleScroller === `${key}` ? 1 : 0, presets.opacity),
                y: spring(this.state.visibleScroller === `${key}` ? 0 : 65, presets.stiff),
                zIndex: this.state.visibleScroller === `${key}` ? 4 : 0,
                pointerEvents: this.state.visibleScroller === `${key}` ? 1 : 0,
              }}
            >
              {({ opacity, y, zIndex, pointerEvents }) => (
                <div
                  className="fixed bottom-0 w-100 "
                  style={{
                    opacity: `${opacity}`,
                    transform: `translate3d(0, ${y}px, 0)`,
                    zIndex: zIndex,
                    pointerEvents: pointerEvents ? 'auto' : 'none',
                    marginTop: 57,
                  }}
                >
                  <ScrollerVertical height="280px" key={`${key}`}>
                    {addonTypes[key].items.map((addon) => {
                      const isSelected =
                        build.addonLineItems && addon
                          ? !!build.addonLineItems.find((x) => Number(x.addon) === addon.id)
                          : false

                      /* const requirementsMet = areEquipmentRequirementsMet(addon, build)
                      if (!requirementsMet) {
                        return null
                      } */

                      return (
                        <Box
                          selected={isSelected}
                          key={`${addon.slug}`}
                          style={{ height: '280px', maxWidth: '290px' }}
                          className={` pointer relative pv2 w-100 bb-l db ws-normal h-100 ios-anti-click-blink`}
                        >
                          <EquipmentItem
                            equipment={addon}
                            selected={isSelected}
                            slug={addon.slug}
                            type="addonLineItems"
                            value={addon.id}
                            equipmenttype={key}
                            onClick={setSelection}
                          ></EquipmentItem>
                        </Box>
                      )
                    })}
                  </ScrollerVertical>
                </div>
              )}
            </Motion>
          ))}

        {build &&
          options.colorareas.result.map((colorarea, idx) => {
            let prevColorType = ''
            return (
              <Motion
                key={`${colorarea}${idx}`}
                style={{
                  opacity: spring(
                    this.state.visibleScroller === `${colorarea}` ? 1 : 0,
                    presets.opacity
                  ),
                  y: spring(this.state.visibleScroller === `${colorarea}` ? 0 : 65, presets.stiff),
                  zIndex: this.state.visibleScroller === `${colorarea}` ? 4 : 0,
                  pointerEvents: this.state.visibleScroller === `${colorarea}` ? 1 : 0,
                }}
              >
                {({ opacity, y, zIndex, pointerEvents }) => (
                  <div
                    className="fixed bottom-0 w-100"
                    style={{
                      opacity: `${opacity}`,
                      transform: `translate3d(0, ${y}px, 0)`,
                      zIndex: zIndex,
                      pointerEvents: pointerEvents ? 'auto' : 'none',
                      marginTop: 57,
                    }}
                  >
                    <ScrollerVertical
                      height="141px"
                      key={`${colorarea}${idx}${idx}`}
                      bgColor="white"
                    >
                      {(prevColorType = '')}
                      {options.colorareas.entities[colorarea].acf.colors.map((color, idx) => {
                        const curColorType = color.color_type.slug || ''
                        let ColorType = prevColorType !== curColorType && (
                          <div
                            className="flex justify-center w5 ph3 flex-column br b--light-gray"
                            style={{ whiteSpace: 'normal', minWidth: '290px' }}
                            key={`${color.color.term_id}${idx}-colortype`}
                          >
                            <h5 className="f5 mb0 mt1 geo-semibold">{color.color_type.name}</h5>
                            <p className="p-3">{color.color_type.description}</p>
                          </div>
                        )
                        //
                        prevColorType = curColorType
                        const selected =
                          build.colors && color.color.term_id === Number(build.colors[colorarea])
                        return (
                          <>
                            {ColorType}
                            <ScrollerItem
                              key={`${color.color.term_id}${idx}`}
                              data-name={colorarea}
                              data-type="colors"
                              colors={true}
                              data-value={color.color.term_id}
                              onClick={setSelection}
                              selected={selected}
                              style={{ minWidth: '290px', maxWidth: '290px' }}
                            >
                              <div className="flex items-center nt3" style={{ width: '290px' }}>
                                <div className="ma3">
                                  <ColorSwatch
                                    small={true}
                                    color={options.colors.entities[Number(color.color.term_id)]}
                                  />
                                </div>
                                <div className="ml2">
                                  <p className="geo-semibold mb1 f5 lh-solid goldfish-green">
                                    {color.color.name}
                                  </p>
                                  {showPrice && (
                                    <ColorPrice selected={selected}>
                                      <Currency
                                        prices={color.prices}
                                        className="relative pointer-events-none db geo-semibold"
                                      />
                                    </ColorPrice>
                                  )}
                                  {color.color_type && (
                                    <p
                                      className="geo-regular f7 dib ph1 mt0 mb1 pv1 ph2"
                                      style={{ backgroundColor: 'rgb(166, 221, 232)' }}
                                    >
                                      {color.color_type.name}
                                    </p>
                                  )}

                                  {/* <h5
                                    className="flex f5 geo-semibold black lh-title mt2 mb0 bg-light-gray ph2 pv1 br2"
                                    style={{
                                      background: selected ? '#43B245' : '#E9E9E9',
                                      color: selected ? '#fff' : '#000',
                                    }}
                                  >
                                    {selected ? (
                                      <SelectedIcon className="mr2" color="#fff" />
                                    ) : (
                                      <PlusIcon className="mr2" />
                                    )}

                                    {selected ? 'Selected' : 'Add'}
                                  </h5> */}
                                </div>
                              </div>
                            </ScrollerItem>
                          </>
                        )
                      })}
                    </ScrollerVertical>
                  </div>
                )}
              </Motion>
            )
          })}
        <Motion
          style={{
            opacity: spring(this.state.visibleScroller !== 'main' ? 1 : 0, presets.opacity),
            y: spring(this.state.visibleScroller !== 'main' ? 0 : 50, presets.stiff),
            zIndex: this.state.visibleScroller !== 'main' ? 999999 : 0,
          }}
        >
          {({ opacity, y, zIndex }) => (
            <div
              data-value="main"
              className="fixed bottom-0 w-100"
              style={{
                opacity: `${opacity}`,
                transform: `translate3d(0, ${y}px, 0)`,
                zIndex: zIndex,
                height: 64,
              }}
            >
              <div
                className="flex items-center justify-center bg-blue-grey white w-100 bt b--black-05 h-100"
                data-value="main"
              >
                <div
                  data-value="main"
                  className="relative flex items-center justify-between w-100 center h-100"
                >
                  {/* <div data-value="main" className="geo-regular f5 ml1 white">
                    {this.state.visibleScroller === 'drivetrains' ||
                    this.state.visibleScroller === 'seats' ||
                    Object.keys(equipmentTypes).indexOf(this.state.visibleScroller) !== -1 ||
                    this.state.visibleScroller === 'tops' ||
                    this.state.visibleScroller === 'main'
                      ? this.state.visibleScroller.replace(/^\w/, (c) => c.toUpperCase())
                      : `${
                          options.colorareas.entities[this.state.visibleScroller] &&
                          options.colorareas.entities[this.state.visibleScroller].acf.display_name
                        } Color`}
                  </div> */}
                  <div className="w-10 flex h-100 items-center">
                    <div className="br-ns border-dark h-100 flex items-center">
                      <div className="flex-auto truncate geo-semibold p-1 ph4">
                        <span className="geo-semibold white">
                          {this.state.visibleScroller === 'drivetrains' ||
                          this.state.visibleScroller === 'seats' ||
                          Object.keys(equipmentTypes).indexOf(this.state.visibleScroller) !== -1 ||
                          Object.keys(addonTypes).indexOf(this.state.visibleScroller) !== -1 ||
                          this.state.visibleScroller === 'tops' ||
                          this.state.visibleScroller === 'main'
                            ? this.state.visibleScroller.replace(/^\w/, (c) => c.toUpperCase())
                            : `${
                                options.colorareas.entities[this.state.visibleScroller] &&
                                options.colorareas.entities[this.state.visibleScroller].acf
                                  .display_name
                              } Color`}
                        </span>
                      </div>
                    </div>
                    <div className="dn br border-dark h-100 flex-ns items-center">
                      <div className="flex-auto truncate geo-semibold p-1 ph4">
                        <span className="geo-semibold blue-green">
                          {this.state.visibleScroller === 'drivetrains' && 'Step 1/4'}
                          {this.state.visibleScroller === 'seats' && 'Step 2/4'}
                          {this.state.visibleScroller === 'tops' && 'Step 2/4'}
                          {!!options.colorareas.entities[this.state.visibleScroller] && 'Step 3/4'}
                          {this.state.visibleScroller === 'main' && 'Step 3/4'}
                          {this.state.visibleScroller === 'equipments' && 'Step 4/4'}
                          {this.state.visibleScroller === 'performance' && 'Step 4/4'}
                          {this.state.visibleScroller === 'options' && 'Step 4/4'}
                          {this.state.visibleScroller === 'navigation' && 'Step 4/4'}
                          {this.state.visibleScroller === 'accessories' && 'Step 4/4'}
                          {this.state.visibleScroller === 'rear-seats' && 'Step 4/4'}
                        </span>
                      </div>
                    </div>
                  </div>
                  {
                    /*this.state.visibleScroller !== 'drivetrains' &&*/
                    this.state.visibleScroller !== 'main' && (
                      <div className="w-30-ns ph4-ns ph3 pv3 h-100">
                        <Menu
                          data-value="main"
                          onClick={this.setVisibleScroller.bind(this)}
                          className="flex items-center w-40-ns right pt-icon-tick w6 f5 bg-blue-grey white ph4-ns tc pointer ba border-orange ph3"
                        >
                          {/* <span
                            data-value="main"
                            className="di f7 bp3-icon-standard bp3-icon-delete mr2 pb1"
                            style={{ fontSize: '14px' }}
                          /> */}
                          {/* this one was previous "go back to menu" and changed to "back" */}
                          <span
                            data-value="main"
                            className="pointer geo-semibold ttu mr-auto ml-auto"
                          >
                            <BackArrow
                              className="mr1 back-image"
                              width={14}
                              title=""
                              dataValue="main"
                            />
                            Back
                          </span>
                        </Menu>
                      </div>
                    )
                  }
                  {/* {this.state.visibleScroller === 'drivetrains' && (
                    <div className="w4" data-value="main">
                      <NextLink data-value="main" to={`${match.url}/style/tops-and-seats`}>
                        Next
                      </NextLink>
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          )}
        </Motion>
      </Wrapper>
    )
  }
}

export default Style
