import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import titleCase from 'title-case'

import Select from '../Select/Select'
import Currency from '../../components/Currency/Currency'

const ConfigurationLineItem = ({ handleChange, name, options, currentSelectedOption }) => (
  <div>
    <div className="flex items-center justify-between">
      <Field
        name={name}
        label={titleCase(name)}
        component={Select}
        placeholder={`Select a ${name} option`}
        type="select"
        options={options}
        classes="w-75 mr4"
        validate={[]}
        autocomplete={name}
        handleChange={handleChange}
      />
      <Currency prices={currentSelectedOption} />
    </div>
    <hr className="mv2" />
  </div>
)

ConfigurationLineItem.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  currentSelectedOption: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
}

export default ConfigurationLineItem
