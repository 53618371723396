import PropTypes from 'prop-types';
import React from 'react';

const Section = ({children}) => (
  <section className='mw8 center pv4 ph3'>
    {children}
  </section>
)

Section.propTypes = {
  children: PropTypes.node.isRequired
}

export default Section
