import { z } from 'zod';
import * as s from './shared';

const topTypeSchema = s.wpOptionEntrySchema.extend({
  boatmodel: z.array(z.number()),
  acf: z.object({
    display_name: z.string(),
    price: z.union([z.string(), z.number()]),
    price_eur: z.union([z.string(), z.number()]),
    price_sek: z.union([z.string(), z.number()]),
    price_usd: z.union([z.string(), z.number()]),
    description: z.string(),
    image: s.wpImageSchema,
    thumbnail: s.wpImageSchema,
    speed_penalty: z.string(),
    label: z.string(),
    shapediver_property_id: z.string(),
    shapediver_option_id: z.string(),
  }),
  prices: s.pricesSchema,
});

export type TopOption = z.infer<typeof topTypeSchema>;

export default z.array(topTypeSchema);
