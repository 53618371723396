const drivetrainCardProps = {
  'data-name': 'drivetrain',
  'data-value': 13726,
  id: 13726,
  drivetrain: {
    id: 13726,
    date: '2018-02-06T00:00:00',
    date_gmt: '2018-02-05T23:00:00',
    guid: {
      rendered:
        'http://goldfishboat.staging.wpengine.com/drivetrain/single-mercury-4-stroke-400bhp-28-bullet/',
    },
    modified: '2019-11-28T11:27:25',
    modified_gmt: '2019-11-28T10:27:25',
    slug: 'single-mercury-4-stroke-400bhp-28-bullet',
    status: 'publish',
    type: 'drivetrains',
    link:
      'https://goldfishboat.staging.wpengine.com/drivetrain/single-mercury-4-stroke-400bhp-28-bullet/',
    title: { rendered: 'i. Mercury Racing 450BHP &#8211; 28 Bullet' },
    content: { rendered: '', protected: false },
    featured_media: 0,
    template: '',
    boatmodel: [89],
    acf: {
      faqs: false,
      days: '',
      configuratorTemplateLink: false,
      build_number: '',
      specification: false,
      hide_model_specs: false,
      colors: false,
      drivetrain_name: '',
      drivetrain_slogan: '',
      drivetrain_intro: '',
      drivetrain_image: '',
      drivetrain_table: false,
      standard_equipment: false,
      options: false,
      accessories: false,
      documents: false,
      order_date: '',
      finished_build_date: '',
      delivery_date: '',
      build_gallery: false,
      custom_status_title: '',
      custom_status_message: '',
      owner: false,
      previous_owner: false,
      agent: false,
      text: '',
      latitude: '',
      longitude: '',
      email: '',
      city: '',
      country: '',
      manufacturing_year: '',
      price: '1600000',
      price_small_text: '',
      links: false,
      table: false,
      slide: false,
      imagebox: false,
      right_column: '',
      display_name: 'Mercury Racing V8 450R',
      description: '',
      hide_configurator_promo_module: false,
      intro:
        'Blistering performance makes this engine the premium choice with experienced Goldfish enthusiast. the 450R features a 4.6-liter V8 FourStroke powerhead boosted by an exclusive Mercury Racing supercharger to produce 450 peak propshaft horsepower and 40 percent higher torque than the powerful 400R. Considerable lighter than the nearest competitor, the new 450R delivers industry leading power-to-weight in a compact, efficient package.',
      lead: '',
      navigational_image: '',
      page_blocks: [
        { acf_fc_layout: 'text', title: '', subtitle: '', body: '' },
        { acf_fc_layout: 'image_slider', assets: false },
        { acf_fc_layout: 'images', assets: false },
        { acf_fc_layout: 'table', rows: false },
        { acf_fc_layout: 'quote', text: '', quotee: '' },
        { acf_fc_layout: 'key_features', features: false },
        { acf_fc_layout: 'video', video: '' },
      ],
      onboarding: false,
      codes_keys_and_numbers: false,
      key_actions: false,
      slides: false,
      heading: '',
      tittel: '',
      tekst: '',
      service_title: 'g. Single Mercury 4-stroke 400BHP - 28 Bullet',
      service_text: '',
      service_list_items: false,
      features: false,
      facts: false,
      image: {
        ID: 16355,
        id: 16355,
        title: '1x-Mercury-450r',
        filename: '1x-Mercury-450r.gif',
        filesize: 122368,
        url:
          'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2017/01/1x-Mercury-450r.gif',
        link:
          'https://goldfishboat.staging.wpengine.com/drivetrain/single-mercury-4-stroke-400bhp-28-tender/1x-mercury-450r/',
        alt: '',
        author: '2',
        description: '',
        caption: '',
        name: '1x-mercury-450r',
        status: 'inherit',
        uploaded_to: 11077,
        date: '2019-08-23 08:50:01',
        modified: '2019-08-23 08:50:01',
        menu_order: 0,
        mime_type: 'image/gif',
        type: 'image',
        subtype: 'gif',
        icon: 'https://goldfishboat.staging.wpengine.com/wp-includes/images/media/default.png',
        width: 450,
        height: 864,
        sizes: {
          thumbnail:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2017/01/1x-Mercury-450r-150x150.gif',
          'thumbnail-width': 150,
          'thumbnail-height': 150,
          medium:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2017/01/1x-Mercury-450r-156x300.gif',
          'medium-width': 156,
          'medium-height': 300,
          medium_large:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2017/01/1x-Mercury-450r.gif',
          'medium_large-width': 450,
          'medium_large-height': 864,
          large:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2017/01/1x-Mercury-450r.gif',
          'large-width': 450,
          'large-height': 864,
          'slider-size':
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2017/01/1x-Mercury-450r-450x300.gif',
          'slider-size-width': 450,
          'slider-size-height': 300,
        },
      },
      logo: {
        ID: 13729,
        id: 13729,
        title: 'Mercury-Racing-Wordmark_RACEWAY-REV-White-2.png',
        filename: 'Mercury-Racing-Wordmark_RACEWAY-REV-White-2.png',
        filesize: 42063,
        url:
          'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-Racing-Wordmark_RACEWAY-REV-White-2.png',
        link:
          'https://goldfishboat.staging.wpengine.com/drivetrain/single-mercury-4-stroke-400bhp-28-bullet/mercury-racing-wordmark_raceway-rev-white-2-png/',
        alt: '',
        author: '2',
        description: '',
        caption: '',
        name: 'mercury-racing-wordmark_raceway-rev-white-2-png',
        status: 'inherit',
        uploaded_to: 13726,
        date: '2018-03-16 12:21:48',
        modified: '2018-03-16 12:21:48',
        menu_order: 0,
        mime_type: 'image/png',
        type: 'image',
        subtype: 'png',
        icon: 'https://goldfishboat.staging.wpengine.com/wp-includes/images/media/default.png',
        width: 800,
        height: 285,
        sizes: {
          thumbnail:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-Racing-Wordmark_RACEWAY-REV-White-2-150x150.png',
          'thumbnail-width': 150,
          'thumbnail-height': 150,
          medium:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-Racing-Wordmark_RACEWAY-REV-White-2-300x107.png',
          'medium-width': 300,
          'medium-height': 107,
          medium_large:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-Racing-Wordmark_RACEWAY-REV-White-2.png',
          'medium_large-width': 800,
          'medium_large-height': 285,
          large:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-Racing-Wordmark_RACEWAY-REV-White-2.png',
          'large-width': 800,
          'large-height': 285,
          'slider-size':
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-Racing-Wordmark_RACEWAY-REV-White-2-450x285.png',
          'slider-size-width': 450,
          'slider-size-height': 285,
        },
      },
      stats: [
        { title: 'Performance', value: '90' },
        { title: 'Comfort', value: '80' },
        { title: 'Simplicity', value: '100' },
        { title: 'Economy', value: '70' },
      ],
      meta: [
        { title: 'Max Speed', value: '68 kn' },
        { title: 'High Cruising', value: '55 kn' },
        { title: 'Low Cruising', value: '20 kn' },
        { title: '40kn/46mph Comsumption', value: '1.0 l/nm' },
        { title: 'Cruising Range', value: '400 nm' },
        { title: 'Propeller', value: 'TBD' },
        { title: 'Full Throttle RPM Range', value: '5800 - 6400 RPM' },
        { title: 'Cylinder Arrangement', value: 'V8' },
        { title: 'Displacement', value: '4600 cc' },
      ],
      hex: '',
      category: '',
      slogan: '',
      standard_specification: false,
      tagline: '',
      boatimage: {
        ID: 13730,
        id: 13730,
        title: 'Mercury-400-6-2.png',
        filename: 'Mercury-400-6-2.png',
        filesize: 168403,
        url:
          'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-400-6-2.png',
        link:
          'https://goldfishboat.staging.wpengine.com/drivetrain/single-mercury-4-stroke-400bhp-28-bullet/mercury-400-6-2-png/',
        alt: '',
        author: '2',
        description: '',
        caption: '',
        name: 'mercury-400-6-2-png',
        status: 'inherit',
        uploaded_to: 13726,
        date: '2018-03-16 12:21:48',
        modified: '2018-03-16 12:21:48',
        menu_order: 0,
        mime_type: 'image/png',
        type: 'image',
        subtype: 'png',
        icon: 'https://goldfishboat.staging.wpengine.com/wp-includes/images/media/default.png',
        width: 450,
        height: 864,
        sizes: {
          thumbnail:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-400-6-2-150x150.png',
          'thumbnail-width': 150,
          'thumbnail-height': 150,
          medium:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-400-6-2-156x300.png',
          'medium-width': 156,
          'medium-height': 300,
          medium_large:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-400-6-2.png',
          'medium_large-width': 450,
          'medium_large-height': 864,
          large:
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-400-6-2.png',
          'large-width': 450,
          'large-height': 864,
          'slider-size':
            'https://goldfishboat.staging.wpengine.com/wp-content/uploads/2018/03/Mercury-400-6-2-450x300.png',
          'slider-size-width': 450,
          'slider-size-height': 300,
        },
      },
      sub_heading: '',
      slug: 'v8-450r-single',
      motor_type_new: 'outboard',
      product_sku: '',
      shapediver_property_id: '28511da8-a681-4a43-9060-8be76185c1e3',
      shapediver_option_id: 'Mercury_Racing_V8_450R',
    },
    _links: {
      self: [{ href: 'https://goldfishboat.staging.wpengine.com/wp-json/wp/v2/drivetrains/13726' }],
      collection: [{ href: 'https://goldfishboat.staging.wpengine.com/wp-json/wp/v2/drivetrains' }],
      about: [
        { href: 'https://goldfishboat.staging.wpengine.com/wp-json/wp/v2/types/drivetrains' },
      ],
      'version-history': [
        {
          count: 0,
          href:
            'https://goldfishboat.staging.wpengine.com/wp-json/wp/v2/drivetrains/13726/revisions',
        },
      ],
      'wp:attachment': [
        { href: 'https://goldfishboat.staging.wpengine.com/wp-json/wp/v2/media?parent=13726' },
      ],
      'wp:term': [
        {
          taxonomy: 'boatmodel',
          embeddable: true,
          href: 'https://goldfishboat.staging.wpengine.com/wp-json/wp/v2/boatmodel?post=13726',
        },
      ],
      curies: [{ name: 'wp', href: 'https://api.w.org/{rel}', templated: true }],
    },
  },
  selected: false,
  showPrice: true,
}

const stuff = { drivetrainCardProps }

export default stuff
