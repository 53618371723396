import React from 'react'

import styled from 'styled-components'
import usePosition from '../../helpers/usePosition'

const TouchScrollerOuter = styled.section`
  width: 100%;
  position: relative;
  overflow: hidden;
`

const TouchScroller = styled.div`
  /* overflow-x: scroll; */
  -ms-overflow-style: none;
  background-color: ${(props) => (props.bgColor ? props.bgColor : '#0d282e')};
  margin-bottom: ${(props) => (props.mBottom ? props.mBottom : '64px')};
  height: ${(props) => (props.height ? props.height : '120px')};
  @media (min-width: 64em) {
    height: ${(props) => (props.height ? props.height : '120px')};
  }
`

const ArrowLeft = ({ size = 15, color = '#fff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M19 12H6M12 5l-7 7 7 7" />
  </svg>
)

const ArrowRight = ({ size = 15, color = '#fff' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M5 12h13M12 5l7 7-7 7" />
  </svg>
)

const ButtonContainer = styled.button`
  position: absolute;
  cursor: pointer;
  top: 0;
  z-index: 999;
  width: 70px;
  height: calc(100% - 63px);
  transition: transform 0.1s ease-in-out;
  display: none;
  align-items: center;
  background: transparent;
  border: none;
  @media (min-width: 30em) {
    display: flex;
  }
`

const RightButtonContainer = styled(ButtonContainer)`
  right: 0;
  justify-content: end;
  visibility: ${({ hasItemsOnRight }) => (hasItemsOnRight ? `visible` : `hidden`)};
`
const LeftButtonContainer = styled(ButtonContainer)`
  left: 0;
  justify-content: start;
  visibility: ${({ hasItemsOnLeft }) => (hasItemsOnLeft ? `visible` : `hidden`)};
`

const CarouselButton = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  z-index: 99;
  transition: transform 0.1s ease-in-out;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
  border: 1.5px solid white;
  border-radius: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

const RightCarouselButton = styled(CarouselButton)`
  ${RightButtonContainer}:hover & {
    transform: scale(1.1);
  }
  transform-origin: center;
`

const LeftCarouselButton = styled(CarouselButton)`
  ${LeftButtonContainer}:hover & {
    transform: scale(1.1);
  }
  transform-origin: center;
`

const ScrollerVertical = ({ children, ...rest }) => {
  const ref = React.useRef()
  const { hasItemsOnLeft, hasItemsOnRight, scrollRight, scrollLeft } = usePosition(ref)

  // useEffect(() => {
  //   ref.current.scroll({
  //     left: 0,
  //   })
  // }, [children])

  return (
    <TouchScrollerOuter>
      <TouchScroller ref={ref} {...rest} className="flex nowrap h4 mb0 pv0 overflow-y-hidden">
        {children}
      </TouchScroller>
      <LeftButtonContainer hasItemsOnLeft={hasItemsOnLeft} onClick={scrollLeft}>
        <LeftCarouselButton>
          <ArrowLeft />
        </LeftCarouselButton>
      </LeftButtonContainer>
      <RightButtonContainer hasItemsOnRight={hasItemsOnRight} onClick={scrollRight}>
        <RightCarouselButton>
          <ArrowRight />
        </RightCarouselButton>
      </RightButtonContainer>
    </TouchScrollerOuter>
  )
}

export default ScrollerVertical
