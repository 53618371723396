import * as Sentry from '@sentry/browser';
import { z } from 'zod';

import { getOptionByType } from './getOptionByType';

import addonsSchema from './schemas/addons';
import boatsSchema from './schemas/boats';
import colorSchema from './schemas/color';
import colorareasSchema from './schemas/colorareas';
import drivetrainsSchema from './schemas/drivetrains';
import equipmentsSchema from './schemas/equipments';
import seatsSchema from './schemas/seats';
import topsSchema from './schemas/tops';
import { OptionType } from './getOptionByType';

const allOptionsSchema = z.object({
  addons: addonsSchema,
  boats: boatsSchema,
  color: colorSchema,
  colorareas: colorareasSchema,
  drivetrains: drivetrainsSchema,
  equipments: equipmentsSchema,
  seats: seatsSchema,
  tops: topsSchema,
});

export type GetAllOptionsForModelResult = z.infer<typeof allOptionsSchema>;

export async function getAllOptionsForModel(
  boatModel: string
): Promise<GetAllOptionsForModelResult> {
  /**
   * The list of option types to be fetched from the backend, in no particular order.
   */
  const optionTypes: Array<OptionType> = [
    'addons',
    'boats',
    'color',
    'colorareas',
    'drivetrains',
    'equipments',
    'seats',
    'tops',
  ];

  /**
   * First, load all option results from the backend into an array
   */
  const resultArray = await Promise.all(
    optionTypes.map(async (optionType) => {
      const data = await getOptionByType(optionType, {
        boatModel,
        _embed: optionType === 'addons' || optionType === 'equipments',
      });
      return {
        optionType,
        data,
      };
    })
  );

  /**
   * Second, we put in into a map for more convenient access and validation
   */
  const resultObject = resultArray.reduce((acc, cur) => {
    acc[cur.optionType] = cur.data;
    return acc;
  }, {} as { [key in OptionType]: any });

  /**
   * Third, we validate the result according to the zod schema
   */
  const validatedResult = allOptionsSchema.safeParse(resultObject);

  /**
   * If the validation fails, we log the error and return the result object
   * as fallback even though it failed the validation.
   */
  if (!validatedResult.success) {
    console.error('API validation error:', validatedResult.error.issues);
    Sentry.captureMessage('Error: Validation of API return options failed', {
      tags: {
        issues: JSON.stringify(validatedResult.error.issues),
      },
    });
    return resultObject; // Return unvalidated data as fallback
  }

  return validatedResult.data;
}
