import PropTypes from 'prop-types'
import { Component } from 'react'
import { Field } from 'redux-form'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Button } from '@blueprintjs/core'

import DragHandle from '../../components/DragHandle/DragHandle'
import Input from '../../components/Input/Input'
import InputGroup from '../../components/InputGroup/InputGroup'
import * as validator from '../../helpers/validators.js'

const SortableItem = SortableElement(({ item, currency, itemIndex, handleRemoveItem }) => (
  <li className="flex flex-wrap">
    <Field
      name={`${item}.description`}
      component={Input}
      label="Description"
      placeholder="Add item description"
      type="text"
      validate={[validator.required]}
      classes="flex-auto mr4-ns"
    />
    <Field
      name={`${item}.cost`}
      component={InputGroup}
      label="Cost"
      placeholder="Add item cost"
      type="text"
      classes="w-20 mr3"
      currency={currency}
    />
    <div className="pt2 flex items-center bp3-button-group bp3-minimal">
      <Button type="button" icon="delete" data-index={itemIndex} onClick={handleRemoveItem} />
      <DragHandle />
    </div>
  </li>
))

const SortableList = SortableContainer(({ currency, handleRemoveItem, options, fields }) => (
  <div>
    {fields.map((item, index) => (
      <SortableItem
        key={`item-${index}`}
        handleRemoveItem={handleRemoveItem}
        index={index}
        itemIndex={index}
        item={item}
        options={options}
        currency={currency}
      />
    ))}
  </div>
))

class CustomLineItem extends Component {
  constructor(props) {
    super(props)
    this.onSortEnd = this.onSortEnd.bind(this)
    this.handleRemoveItem = this.handleRemoveItem.bind(this)
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.props.fields.move(oldIndex, newIndex)
  }

  handleRemoveItem = (e) => {
    this.props.fields.remove(e.target.dataset.index)
  }

  render() {
    const {
      options,
      fields,
      currency,
      meta: { touched, error },
    } = this.props

    return (
      <ul className="list pl0">
        <SortableList
          lockAxis={'y'}
          useDragHandle
          handleRemoveItem={this.handleRemoveItem}
          currency={currency}
          options={options}
          fields={fields}
          onSortEnd={this.onSortEnd}
        />
        <li className="flex bp3-button-group">
          <Button icon="add" type="button" onClick={() => fields.push({})}>
            Add equipment
          </Button>
          {touched && error && <span>{error}</span>}
        </li>
      </ul>
    )
  }
}

CustomLineItem.propTypes = {
  fields: PropTypes.object,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
}

export default CustomLineItem
