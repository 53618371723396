import React, { ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';
import { lightenDarkenColor } from '../../helpers/colorTransforms';

interface WrapperProps {
  selected?: boolean;
  disabled?: boolean;
}

const Wrapper = styled.button<WrapperProps>`
  @media (min-width: 30em) {
    min-width: 200px;
  }
  height: 35px;
  margin: auto;
  border: 0;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  transition: all 0.1s ease-in;
  min-width: 90px;

  background-color: ${(props) =>
    props.disabled ? props.theme.primaryLightGrey : props.theme.primaryOrange};
  &:hover {
    background-color: ${(props) => lightenDarkenColor(props.theme.primaryOrange, 20)};
    text-decoration: none;
    color: #fff;
    text-decoration: none;
  }
`;

interface Props {
  style?: CSSProperties;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: ReactNode;
}

function ConfirmButton(props: Props) {
  return (
    <Wrapper
      onClick={props.onClick}
      className={`geo-semibold f5 white pv2 pointer link input-reset w-100 br2 ph3 flex flex-auto items-center justify-center ${props.className}`}
      style={props.style}
    >
      {props.children}
    </Wrapper>
  );
}

export default ConfirmButton;
