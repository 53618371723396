import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import styled from 'styled-components'
import * as validator from '../../helpers/validators.js'
import Input from '../../components/Input/Input'
import Select from '../../components/Select/Select'
import SelectCountry from '../../components/Select/SelectCountry'
import Textarea from '../../components/Textarea/Textarea'
import SimpleButton from '../../components/SimpleButton/SimpleButton'
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount'
import BackLink from '../BackLink/BackLink'
import countryCodes from './countryCodes'
import { config } from '../../config.js'

const Label = styled.label`
  input:focus + div {
    box-shadow: 0 0 0 3px gold;
    outline: 3px solid transparent;
  }
`

const StyledP = styled.p`
  a {
    color: #fb5515;
  }
`

const Checkbox = ({ input, name, required, meta: { touched, error, warning } }) => (
  <Label
    htmlFor={name}
    className={`pointer flex align-start pa2 bt b--light-gray ${input.value && 'bg-lightest-blue'}`}
    style={
      input.value
        ? { background: '#eaf1f0', paddingTop: '1.5rem', paddingBottom: '1.5rem' }
        : { paddingTop: '1.5rem', paddingBottom: '1.5rem' }
    }
  >
    <input
      className="w3 h3 absolute top-0 left-0 o-0"
      type="checkbox"
      checked={input.value ? true : false}
      id={name}
      onClick={() => {
        input.onChange(!input.value)
      }}
    />
    <div
      className={`ba bw1 b--near-white bg-white br-100`}
      style={{ width: '20px', height: '20px', 'border-color': '#e6e6e6' }}
    >
      <div
        className={`br-100 ${!input.value && 'o-0'}`}
        style={{ width: '12px', height: '12px', margin: '2px', background: '#fb6e37' }}
      ></div>
    </div>
    <div class="ml3">
      <h4 className="f5 geo-semibold mb2 mt0">Accept terms</h4>
      <p className="f5 geo-regular mt0">
        By clicking "Accept and go to payment" you agree to the terms of the Goldfish Sales &
        Purchase Agreement.{' '}
        <a
          className="link geo-semibold hover-orange"
          style={{ color: '#fb6e37' }}
          target="_blank"
          href={config.linkToSalesAgreement}
          rel="noopener noreferrer"
        >
          Read the whole agreement here
        </a>
      </p>
    </div>
    {touched && error && <span className="mt1 db red roboto-regular">{error}</span>}
  </Label>
)

class QuoteForm extends Component {
  constructor(props) {
    super(props)
    this.state = { step: 1 }
    this.handleNextStep = this.handleNextStep.bind(this)
  }

  handleNextStep(step) {
    this.setState({ step: step })
  }

  render() {
    const { handleSubmit, pristine, submitting, onSubmit, requestType, terms } = this.props
    const { step } = this.state

    this.props.change('requestType', requestType)

    return (
      <form
        className="mw7 center flex flex-wrap"
        onSubmit={
          requestType === 'pay-deposit' && step === 1
            ? handleSubmit(() => this.handleNextStep(2))
            : handleSubmit(onSubmit)
        }
      >
        {step === 1 && (
          <>
            <section className="mw8 ph3 center">
              <h1 className="geo-regular f5 mt4 mb0 tc">Checkout</h1>
              <h2 className="geo-semibold f3 mt1 tc">Personal info</h2>
              <p className="f5 roboto-regular black-80 mb4 tc">
                {requestType === 'pay-deposit'
                  ? 'Please fill in your personal information.'
                  : 'Please fill in this form and we will review your request and contact you within 48 hours.'}
              </p>

              <Field
                classes="mt2  w-100 s"
                name="firstName"
                component={Input}
                type="text"
                placeholder="Add your first name"
                label="First name *"
                validate={validator.required}
              />
              <Field
                classes="mt2  w-100 "
                name="lastName"
                component={Input}
                type="text"
                placeholder="Add your last name"
                label="Last name *"
                validate={validator.required}
              />
              <Field
                classes="mt2 w-100 s"
                name="email"
                component={Input}
                type="email"
                placeholder="Add your email"
                label="Email *"
                validate={[validator.required, validator.email]}
              />
              <div className="flex w-100">
                <Field
                  name="countryCode"
                  component={Select}
                  label="Country"
                  placeholder="Code"
                  type="select"
                  options={countryCodes}
                  validate={validator.required}
                  classes="mt2 w-30 "
                  // autocomplete="country"
                />
                <Field
                  classes="mt2 w-100 pl2"
                  name="phoneNumber"
                  component={Input}
                  type="phone"
                  placeholder="Add your phone number"
                  label="Phone number *"
                  validate={validator.required}
                />
              </div>
              <Field
                classes="mt2  w-100 s"
                name="address"
                component={Input}
                type="address"
                placeholder="Add street name and number"
                label="Address"
                validate={validator.required}
              />
              <Field
                classes="mt2  w-100 s"
                name="city"
                component={Input}
                type="address"
                placeholder="City"
                label="City"
                validate={validator.required}
              />
              <Field
                classes="mt2  w-100 s"
                name="zip"
                component={Input}
                type="address"
                placeholder="add zip code"
                label="Zip code"
                validate={validator.required}
              />
              <Field
                name="country"
                component={SelectCountry}
                label="Country"
                placeholder="Add your country"
                type="select"
                validate={validator.required}
                options={countryCodes}
                // options={[
                //   'Yacht Tender',
                //   'Norway',
                //   'Canada',
                //   'Finland',
                //   'France',
                //   'Germany',
                //   'Greece',
                //   'Italy',
                //   'Mexico',
                //   'Russia',
                //   'Spain',
                //   'Sweden',
                //   'Switzerland',
                //   'Turkey',
                //   'United Kingdom',
                //   'United States',
                //   'Other',
                // ]}
                classes="mt2  w-100 "
                autocomplete="country"
              />
              {/* <Field
                name="country"
                component={Select}
                label="Country"
                placeholder="Code"
                type="select"
                options={countryCodes}
                classes="mt2 w-100 "
                // autocomplete="country"
              /> */}
              <Field
                classes="mt2  w-100 s"
                name="distributor"
                component={Input}
                type="distributor"
                placeholder="Add your Goldfish Sales Agent"
                label="Goldfish Sales Agent"
              />
              <Field
                classes="mt2  w-100 "
                name="customerComments"
                component={Textarea}
                type="textarea"
                placeholder="Add your message"
                label="Message for the Goldfish Boat Sales Team"
              />
              {requestType === 'pay-deposit' ? (
                <div className="flex-auto flex-none-ns  center mt3 mb3">
                  <SimpleButton disabled={pristine || submitting} type="submit">
                    Next
                  </SimpleButton>
                </div>
              ) : (
                <div className="flex-auto flex-none-ns  center mt3 mb3">
                  <SimpleButton disabled={pristine || submitting} type="submit">
                    {submitting ? 'Submitting...' : 'Submit Request'}
                  </SimpleButton>
                </div>
              )}
              <div className="flex-auto flex-none-ns  center mt3 mb3">
                <BackLink large={true} to={`../summary`} color="black">
                  Previous
                </BackLink>
              </div>
            </section>
          </>
        )}
        {step === 2 && (
          <>
            <ScrollToTopOnMount />
            <section className="mw8 ph3 center">
              <h1 className="tc geo-regular f5 mt4 mb0">Checkout</h1>
              <h2 className="tc geo-semibold f3 mt1">Terms and conditions</h2>
              {terms &&
                terms.map((term, index) => (
                  <div className="flex align-start mb3 ">
                    <div className="w2 mh2">
                      <img src={term.icon} alt=""></img>
                    </div>
                    <div className="w-100">
                      <h4 className="f5 geo-semibold mb2 mt0">{term.headline}</h4>
                      <StyledP className="f5 geo-regular mt0">
                        <div dangerouslySetInnerHTML={{ __html: term.text }} />
                      </StyledP>
                    </div>
                  </div>
                ))}
              <Field
                name="termsAndConditions"
                component={Checkbox}
                type="checkbox"
                validate={validator.required}
              />
              <div
                className="flex-auto flex-none-ns w-50-ns center mt3 mb3"
                style={{ width: '100%' }}
              >
                <SimpleButton type="submit" disabled={pristine || submitting}>
                  Accept and go to payment
                </SimpleButton>
              </div>
              <BackLink
                onClick={() => {
                  this.setState({ step: 1 })
                }}
              >
                Previous
              </BackLink>
            </section>
          </>
        )}
      </form>
    )
  }
}

// Decorate the form component
// eslint-disable-next-line
QuoteForm = reduxForm({
  form: 'quote', // a unique name for this form
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
})(QuoteForm)

export default QuoteForm
