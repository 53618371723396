import { BoatDefinition } from '../../api/getBoatDefinitions';
import { OptionsState } from './reducers';

export const getOptions: (state: any) => OptionsState = (state: any) =>
  state.options as OptionsState;

export const getOptionsIsFetching: (state: any) => boolean = (state: any) =>
  state.options._isFetching;

export const getBoatDefinitionsById: (state: any) => { [id: number]: BoatDefinition } = (
  state: any
) => state.options.boatDefinitionsById;
