import React from 'react'

function Icon() {
  return (
    <svg
      style={{ width: '14px' }}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#fb5515"
        stroke="#fb5515"
        strokeWidth="0.25"
        d="M.875 7.462v.125h5.538v5.538h1.174V7.587h5.538V6.413H7.587V.875H6.413v5.538H.875v1.049z"
      ></path>
    </svg>
  )
}

export default Icon
