import { SVGProps } from 'react';

const SvgEurFlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} fill="none" {...props}>
    <path
      fill="#039"
      d="M14 28c7.732 0 14-6.268 14-14S21.732 0 14 0 0 6.268 0 14s6.268 14 14 14z"
    />
    <path
      fill="#FFCD05"
      d="m14 2.87.33 1.02h1.07l-.86.63.33 1.02-.87-.63-.87.63.33-1.02-.86-.63h1.07L14 2.87zM9.18 4.22l.33 1.02h1.07l-.87.63.33 1.02-.86-.63-.87.63.33-1.02-.87-.63h1.07l.34-1.02zm-3.56 3.4.33 1.02h1.08l-.87.63.33 1.02-.87-.63-.87.63.34-1.02-.87-.63h1.07l.33-1.02zM4.2 12.67l.33 1.01h1.08l-.87.64.33 1.01-.87-.63-.86.63.33-1.01-.87-.64h1.07l.33-1.01zm1.57 4.71.33 1.02h1.07l-.86.63.33 1.02-.87-.63-.87.63.33-1.02-.86-.63h1.07l.33-1.02zm3.28 3.42.33 1.02h1.07l-.87.63.33 1.02-.86-.63-.87.63.33-1.02-.87-.63h1.08l.33-1.02zM14 22.46l.33 1.02h1.07l-.86.63.33 1.02-.87-.63-.87.63.33-1.02-.86-.63h1.07l.33-1.02zm4.95-1.66.33 1.02h1.08l-.87.63.33 1.02-.87-.63-.86.63.33-1.02-.87-.63h1.07l.33-1.02zm3.28-3.42.33 1.02h1.07l-.86.63.33 1.02-.87-.63-.87.63.33-1.02-.86-.63h1.07l.33-1.02zm1.57-4.71.33 1.01h1.07l-.87.64.33 1.01-.86-.63-.87.63.33-1.01-.87-.64h1.08l.33-1.01zm-1.42-5.05.33 1.02h1.07l-.87.63.34 1.02-.87-.63-.87.63.33-1.02-.87-.63h1.08l.33-1.02zM18.81 4.2l.33 1.01h1.07l-.87.64.33 1.01-.86-.63-.87.63.33-1.01-.87-.64h1.07l.34-1.01z"
    />
  </svg>
);

export default SvgEurFlagIcon;
