import { EurFlagIcon, NorwayFlagIcon, SwedenFlagIcon, UsaFlagIcon } from './components/Icons'

/**
 * Public configuration
 *
 * Note that the content of this file will be bundled and exposed
 * to all clients -- do not keep sensitive server credentials here.
 *
 * Use the environment variables if available, but fall back to
 * the production values.
 *
 */
export const config = {
  /**
   * WordPress backend root URL (default to production value if no configuration is provided)
   */
  wordpressRootUrl: import.meta.env.VITE_WORDPRESS_ROOT_URL ?? 'https://my.goldfishboat.com',

  /**
   * Integration backend root URL (no default)
   */
  integrationRootUrl: import.meta.env.VITE_INTEGRATION_ROOT_URL,

  /**
   * Firebase configuration
   */
  firebase: {
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
    useEmulators: import.meta.env.VITE_FIREBASE_USE_EMULATORS === 'true',
  },

  /**
   * Stripe
   */
  stripe: {
    publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  },

  /**
   * Sentry configuration, for error reporting
   */
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    environment: import.meta.env.NODE_ENV === 'production' ? 'production' : 'development',
  },

  /**
   * Currencies used for pricing
   */
  currencies: [
    {
      value: 'NOK',
      label: 'price_nok',
      icon: NorwayFlagIcon,
      includeVat: true, // Note that this boolean only affects text incl(excl -- prices are shown as they are sent from the backend
      info: 'The price includes Norwegian VAT and delivery at the Goldfish Service Dock in Son, Norway.',
    },
    {
      value: 'EUR',
      label: 'price_eur',
      icon: EurFlagIcon,
      includeVat: false,  // Note that this boolean only affects text incl(excl -- prices are shown as they are sent from the backend
      info: 'The price does not include any taxes or transport.',
    },
    {
      value: 'USD',
      label: 'price_usd',
      icon: UsaFlagIcon,
      includeVat: false,  // Note that this boolean only affects text incl(excl -- prices are shown as they are sent from the backend
      info: 'The price includes shipping and import tax to Florida, U.S. Other states; on request.',
    },
    {
      value: 'SEK',
      label: 'price_sek',
      icon: SwedenFlagIcon,
      includeVat: true,  // Note that this boolean only affects text incl(excl -- prices are shown as they are sent from the backend
      info: 'The price includes Swedish VAT and delivery, either at the Goldfish Service Dock in Stockholm or Gothenburg.',
    },
  ],

  /**
   * Content
   */
  content: {
    defaultTerms: `Terms of Payment: A 10% deposit will be invoiced at time of order, sum balance fall due at time of completion. \n Terms of Delivery: The boat is delivered FCA Goldfish Factory. \n To confirm this offer and reserve your build number, please click the “Confirm Order” button below. You will be directed to accept the full Terms and Conditions of the purchase, and then to pay an initial deposit.`,
  },

  linkToSalesAgreement: 'https://my.goldfishboat.com/sale-purchase-agreement/',
  warrantyTermsUrl: 'https://my.goldfishboat.com/warranty-terms/',
}
