import React from 'react'
import styled from 'styled-components'

const PointerNone = styled.div`
  pointer-events: none;
  justify-content: flex-start;
  scroll-snap-align: center;
`

const Wrapper = styled.button`
  background: ${props =>
    props.colors && props.selected
      ? props.theme.primaryLighterGrey
      : props.colors && !props.selected
      ? 'white'
      : props.selected && props.purpleSelect === undefined
      ? 'linear-gradient(to top left, rgb(127,175,185) , rgba(13, 40, 46, 1))'
      : 'linear-gradient(90deg, rgba(37, 61, 67, 1) , rgba(13, 40, 46, 1))'};
  height: 100%;
  border-left: none;
  border-top: none;
  border-bottom: none;
  flex: ${props => (props.flexOption ? props.flexOption : '0 0 auto')};
  @media (min-width: 30em) {
    flex: 0 0 22.5%;
  }
  @media (min-width: 64em) {
    flex: 40%;
    height: 142px;
    width: fit-content;
  }
  outline: none;
  text-align: left;
`

const ScrollerItem = ({ children, selected, ...rest }) => (
  <Wrapper
    selected={selected}
    {...rest}
    className={`pointer relative pv2 w-100 br b--black-10 bb-l db ws-normal h-100 ios-anti-click-blink`}
  >
    <PointerNone className="h-100 center flex flex-column items-center pt1 pt3-ns">
      {children}
    </PointerNone>
  </Wrapper>
)

export default ScrollerItem
