interface Props {
  label: string;
  checked?: boolean | null;
  onChange?: (value: boolean) => void;
}

export function Toggle(props: Props) {
  return (
    <label style={{ display: 'block', padding: '4px' }}>
      <input
        type="checkbox"
        checked={props.checked ?? false}
        onChange={(e) => props.onChange?.(e.target.checked)}
        style={{
          marginRight: '8px',
        }}
      />
      {props.label}
    </label>
  );
}
