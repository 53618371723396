import { z } from 'zod';
import * as s from './shared';

const addonTypeSchema = s.wpOptionEntrySchema.extend({
  addonType: z.array(z.number()),
  boatmodel: z.array(z.number()),
  acf: z.object({
    display_name: z.string(),
    description: z.string(),
    image: s.wpImageSchema,
    thumbnail: s.wpImageSchema,
    speed_penalty: z.string(),
    label: z.string(),
    shapediver_property_id: z.string(),
    shapediver_option_id: z.string(),
  }),
  prices: s.pricesSchema,
  _embedded: z.object({
    'wp:term': z.array(
      z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          slug: z.string(),
          taxonomy: z.string(),
          acf: z.union([
            z.tuple([]), // When the ACF object is empty, it serializes to an empty [] instead of an object.
            z.object({
              only_allow_single_choice: z.boolean(),
              thumbnail: s.wpImageSchema,
            }),
          ]),
        })
      )
    ),
  }),
});

export type AddonOption = z.infer<typeof addonTypeSchema>;

export default z.array(addonTypeSchema);
