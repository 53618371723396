import { Component } from 'react';
import Helmet from 'react-helmet';
// Components
import DrivetrainCard from '../../components/DrivetrainCard/DrivetrainCard';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';

interface Props {
  options: any;
  handleSelect: any;
  build: any;
}

class Drivetrain extends Component<Props> {
  render() {
    const { options, handleSelect, build } = this.props;

    // const boat = boats ? boats.entities[boats.result[0]] : false
    // const showPrice = boat && boat.acf.boat_class === 'consumer'

    const showPrice = true;

    return (
      <section>
        <ScrollToTopOnMount />
        {/* @ts-ignore */}
        <Helmet title={`Drivetrain`} />
        <section className="mw8 center pt2 pb5">
          {options &&
            options.result.map((drivetrain: any) => (
              <DrivetrainCard
                key={drivetrain}
                handleSelect={handleSelect}
                id={drivetrain}
                drivetrain={options.entities[drivetrain]}
                selected={build && build.drivetrain && drivetrain === Number(build.drivetrain)}
                showPrice={showPrice}
              />
            ))}
        </section>
      </section>
    );
  }
}

export default Drivetrain;
