import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// Redux store
import { getUserCurrency } from '../../store/Common/selectors'
import { getBuildById } from '../../store/Builds/selectors'

import { getCurrency } from '../../helpers/getCurrency'

class Currency extends Component {
  render() {
    const {
      children,
      locale = 'no',
      prices,
      currency = 'EUR',
      showVat = false,
      // eslint-disable-next-line
      dispatch,
      build,
      staticContext, // Just to avoid it being added to the ...rest
      ...rest
    } = this.props

    const optionCurrency = getCurrency(currency)
    const vatText = optionCurrency?.includeVat ? 'incl. VAT.' : 'excl. VAT.'

    const price = prices?.[optionCurrency?.label]

    if (price === '0' || price === 0) {
      return (
        <span className="geo-semibold tr" {...rest}>
          Included
        </span>
      )
    }

    if (!price) {
      return (
        <span className="geo-semibold tr o-20" {...rest}>
          Price not set
        </span>
      )
    }

    return (
      <span className="geo-semibold tr" {...rest}>
        {currency}{' '}
        {new Intl.NumberFormat(locale, {
          // style: 'currency', // Removed currency prefix here to rather use consistent currency prefix above
          // currency: currency, // Removed currency prefix here to rather use consistent currency prefix above
          // maximumSignificantDigits: 4,
          // minimumSignificantDigits: 3,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(price)}{' '}
        <span className="geoMedium p-3">{showVat && vatText}</span>
      </span>
    )
  }
}

Currency.propTypes = {
  prices: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  currency: PropTypes.string,
  locale: PropTypes.string,
}

const mapStateToProps = (state, props) => ({
  currency: props.currency ?? getUserCurrency(state),
  build: getBuildById(state, props.match.params.id),
})

export default withRouter(connect(mapStateToProps)(Currency))
