import { z } from 'zod';
import * as s from './shared';

const colorAreaTypeSchema = s.wpOptionEntrySchema.extend({
  boatmodel: z.array(z.number()),
  acf: z.object({
    position: s.coordinatesSchema,
    target: s.coordinatesSchema,
    camera_postion_object: z.string(),
    colors: z.array(
      z.object({
        color: z.object({
          term_id: z.number(),
          name: z.string(),
          slug: z.string(),
          term_group: z.number(),
          term_taxonomy_id: z.number(),
          taxonomy: z.string(),
          description: z.string(),
          parent: z.number(),
          count: z.number(),
          filter: z.string(),
        }),
        price: z.union([z.string(), z.number()]),
        price_eur: z.union([z.string(), z.number()]),
        price_sek: z.union([z.string(), z.number()]),
        price_usd: z.union([z.string(), z.number()]),
        product_sku: z.string(),
        shapediver_property_id: z.string(),

        color_type: z.object({
          term_id: z.number(),
          name: z.string(),
          slug: z.string(),
          term_group: z.number(),
          term_taxonomy_id: z.number(),
          taxonomy: z.string(),
          description: z.string(),
          parent: z.number(),
          count: z.number(),
          filter: z.string(),
        }),
        prices: s.pricesSchema,
      })
    ),
    display_name: z.string(),
    thumbnail: s.wpImageSchema,
    shapediver_property_id: z.string(),
    shapediver_option_id: z.string(),
  }),
});

export type ColorAreaOption = z.infer<typeof colorAreaTypeSchema>;

export default z.array(colorAreaTypeSchema);
