import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
// Components
import Section from '../../components/Section/Section'
// Redux
import { signInWidthGoogle, signup } from '../../store/User/actions'

class Signup extends Component {
  constructor(props) {
    super(props)
    this.state = { errorCode: null, errorMessage: null }
    this.handleSignup = this.handleSignup.bind(this)
    // this.handleGoogleSignup = this.handleGoogleSignup.bind(this)
  }

  static propTypes = {
    user: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  }

  handleSignup(values) {
    this.props
      .signup(values)
      .then((response) =>
        this.setState({ errorCode: response.code, errorMessage: response.message })
      )
  }

  // handleGoogleSignup () {
  //   this.props.signInWidthGoogle()
  // }

  render() {
    // Check if the user has been authenticated
    const { user } = this.props
    const isAuthenticated = user && user.user
    const { from } = this.props.location.state || '/'
    const { errorCode, errorMessage } = this.state
    return (
      <Section>
        {isAuthenticated && <Redirect to={from || '/'} />}
        <h1>Signup</h1>
        {errorCode && (
          <div className="bp3-callout bp3-intent-danger">
            <h5>{errorCode}</h5>
            {errorMessage}
          </div>
        )}
      </Section>
    )
  }
}

// eslint-disable-next-line
const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps, { signInWidthGoogle, signup })(Signup)
