import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { LeftArrow } from '../Icons/LeftArrow';

const Wrapper = styled(Link)<{ disabled?: boolean; large?: boolean }>`
  @media (min-width: 30em) {
    max-width: ${(props) => (props.large ? '100%' : '200px')};
  }
  background-color: transparent;
  color: #869396;
  max-width: 90px;
  height: 35px;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'all')};
  &:hover {
    opacity: 1;
    color: #869396;
    text-decoration: none;

    .back-image {
      opacity: 0.55;
    }
  }
`;

interface Props {
  disabled?: boolean;
  children?: ReactNode;
  direction?: 'left' | 'right'; // @deprecated
  to: string;
  large?: boolean;
}

export function BackLink(props: Props) {
  const { children, to, disabled, direction, ...rest } = props;

  return (
    <Wrapper
      disabled={disabled}
      to={to}
      {...rest}
      className="ba-ns border-orange flex flex-auto items-center justify-center w-100-ns ttu tc db geo-semibold f5 black hover-no-underline no-underline ph1 ph2-ns pv1 pointer input-reset"
    >
      {props.large ? (
        <span
          className="di f7 bp3-icon-standard bp3-icon-arrow-left mr1"
          style={{ fontSize: '14px', marginTop: '2px' }}
        />
      ) : (
        <LeftArrow className="mr1 back-image" width={14} />
      )}

      {children}
    </Wrapper>
  );
}

export default BackLink;
